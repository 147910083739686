export default {
  common: {
    edit: 'Bearbeiten',
    yes: 'Ja',
    no: 'Nein',
    search: 'Suchen',
    none: 'None',
    save: 'Speichern',
    reset: 'Zurücksetzen',
    cancel: 'Abbrechen',
    next: 'Weiter',
    back: 'Zurück',
    selectHeaders: 'Spalten ein- und ausblenden',
    weekday: 'Wochentag',
    from: 'von',
    delete: 'Löschen',
    to: 'bis',
    ok: 'Ok',
    add: 'Hinzufügen',
    remove: 'Entfernen',
    empty: 'There is nothing to see here.',
    selectToImport: 'Bitte eine Datei zum Importieren auswählen',
    selectVenue: 'Bitte wählen Sie eine Venue aus.',
    import: 'Import',
    export: 'Export',
    file: 'Datei',
    apply: 'Anwenden',
    applySelectedFilters: 'Ausgewählte Filter anwenden',
    filters: 'Filter',
    orders: 'Bestellungen',
    overview: 'Overview',
    others: 'Others',
    skip: 'Überspringen',
    time: 'Zeit',
    date: 'Datum',
    register: 'Registrieren',
    submit: 'Bestätigen',
    confirmDelete: 'Sind Sie sicher, dass Sie das Element unwiderruflich löschen wollen?',
    form: {
      file: 'Datei',
    },
    all: 'Alle',
    single: 'Einzeln',
    selectItems: 'Wähen Sie bitte mindestens 1 Element aus.',
    noSelection: 'Keine Auswahl',
    description: 'Beschreibung',
    invoice: 'Rechnung',
    name: 'Name',
    tag: 'Tag',
    tags: 'Tags',
    test: 'Test',
    shoppingCart: 'Warenkorb',
    send: 'Absenden',
    recommendation: 'Empfehlung',
    selectAll: 'Alle auswählen',
  },
  languageField: {
    add: 'Hinzufügen',
    picker: 'Sprache',
  },
  countries: {
    de: 'Deutschland',
    au: 'Österreich',
    fr: 'Frankreich',
    lu: 'Luxemburg',
    ch: 'Schweiz',
    es: 'Spanien',
    is: 'Island',
    ic: 'Kanarische Inseln',
    pt: 'Portugal',
  },
  toolbar: {
    selectOrSearchVenue: 'Select or search venue...',
    noSelection: 'Keine Venue ausgewählt.',
    copyShortId: 'Kurz-ID kopieren',
  },
  login: {
    caption: 'Willkommen bei Smoothr! Smoothr ist die Abkürzung für die Digitalisierung Ihres Restaurants.',
  },
  dashboard: {
    index: 'Dashboard',
    totalSum: 'Gesamtumsatz',
    totalVenues: 'Ergebnisse von {total} Venues.',
    totalNetSum: 'Gesamtumsatz Netto',
    orderSum: 'Anzahl Bestellungen',
    provisionSum: 'Provision',
    form: {
      customerGroup: 'Kundengruppe auswählen',
    },
    table: {
      total: 'Gesamt',
      totalNet: 'Gesamt Netto',
      numberOfOrders: 'Anzahl Bestellungen',
      venue: 'Venue',
      readableId: 'Short-ID',
      provision: 'Provision',
    },
  },
  provision: {
    index: 'Provision',
    overview: 'Übersicht',
    claim: 'Provision in Rechnung stellen',
    select: 'Suche oder wähle Venue aus, um Bezahlungen anzuzeigen',
    claimHelp: 'Wähle Venue und einen Monat aus, um Provision in Rechnung zu stellen.',
    success: 'Success',
    sum: 'Summe',
    waiting: 'Waiting',
    notClaimed: 'Not claimed',
    notification: {
      claiming:
        'Provision wird in Rechnung gestellt. Bitte nach wenigen Minuten die Seite neuladen, um die Änderungen zu sehen.',
    },
    form: {
      venue: 'Venue',
    },
    table: {
      sum: 'Gesamtpreis',
      method: 'Methode',
      status: 'Status',
      createdAt: 'Erstellt am',
      provision: 'Provisions-Status',
      provisionClaimedAt: 'In Rechnung gestellt am',
    },
  },
  inventory: {
    index: 'Inventar',
    overview: 'Übersicht',
    stock: 'Bestand',
    product: 'Produkte',
    add: 'Erstellen',
    form: {
      stock: 'Bestand',
      product: 'Produkte auswählen',
    },
    table: {
      stock: 'Bestand',
      inventoryItems: 'Produkte',
      createdAt: 'Erstellt am',
    },
  },
  notification: {
    form: 'Bitte alle Felder ausfüllen.',
    400: 'Die Anfrage konnte nich verarbeitet werden.',
    401: 'Ihre Zugangsdaten sind nicht korrekt.',
    422: 'Die Felder wurden nicht korrekt ausgefüllt. Bei Fragen wenden Sie sich bitte an unseren Support.',
    404: 'Das Element konnte nicht gefunden werden',
    403: 'Ihnen fehlen die Rechte die Seite einzusehen.',
    500: 'Es gibt ein kurzzeitiges Serverproblem',
    unknown: 'Unbekannter Fehler',
    downloadInProgress:
      'Ihr Download wird vorbereitet, Sie können aber weiterhin auf der Seite navigieren. Der Download Ihrer Datei startet automatisch, sobald diese bereit steht.',
    downloadFailed: 'Einer Ihrer Downloads ist fehlgeschlagen. Probieren Sie es später noch einmal.',
    downloadSuccess: 'Ihr Download ist bereit!',
    gpsError:
      'GPS-Position für die angegebene Adresse konnte nicht ermittelt werden. Bitte ändern Sie die Adresse oder geben Sie die GPS-Koordinaten manuell ein.',
    successfulDownload: 'Der Export wurde an Ihre E-Mail-Adresse gesendet',
  },
  gender: {
    male: 'Männlich',
    female: 'Weiblich',
    other: 'Divers',
  },
  displayModes: {
    default: 'Default',
    launcher: 'Launcher',
    smartPicker: 'Smart Picker',
    detailOverlay: 'Detail Overlay',
    detailDialog: 'Detail Dialog',
    grid: 'Grid',
    pagedGrid: 'Paged Grid',
    circle: 'Circle',
    topSeller: 'Top Seller',
    matrix: 'Matrix',
    banner: 'Banner',
    drinkFlavor: 'Drink Flavor',
    toggleButton: 'Toggle Button',
    flavor: 'Flavor',
    checkbox: 'Checkbox',
    radio: 'Radio',
    code: 'Code',
  },
  displayIdentifiers: {
    extra: 'Extra',
    extra15: 'Extra 15',
    extra30: 'Extra 30',
    extraCheese15: 'Extra Cheese 15',
    extraCheese30: 'Extra Cheese 30',
    size: 'Size',
    size15: 'Size 15',
    size30: 'Size 30',
    cheese: 'Cheese',
    kids: 'Kids',
    salad: 'Salad',
    bread: 'Bread',
    cookie1: '1x Cookie',
    cookie3: '3x Cookie',
    cookie12: '12x Cookie',
    chips: 'Chips',
    sauce: 'Sauce',
    drink: 'Drink',
    water: 'Water',
    tea: 'Tea',
    hotBeverages: 'Hot Beverages',
    menu: 'Menu',
    main: 'Main',
    sub: 'Sub',
    wrap: 'Wrap',
    sides: 'Sides',
    fullPrice: 'Full Price',
    inverse: 'Inverse',
    code: 'Code',
  },
  venueSelector: {
    title: 'Venue auswählen',
    search: 'Venue suchen',
    back: 'Zurück zu Venues',
  },
  currency: {
    euro: 'Euro',
    chf: 'Schweizer Franken',
    isk: 'Isländische Kronen',
  },
  routes: {
    venue: {
      index: 'Venue',
      create: 'Venue erstellen',
      createSimple: 'Venue erstellen',
      copyFoodcard: 'Speisekarte kopieren',
      edit: 'Bearbeiten',
      panic: 'Panic',
      editSimple: 'Bearbeiten',
      verify: 'Verify Venue',
      publish: 'Publish Venue',
      copy: 'Venue kopieren',
    },
    screensaver: {
      index: 'Screensaver',
      create: 'Screensaver erstellen',
      edit: 'Edit screensaver',
    },
    venueLabel: {
      index: 'Venue Label',
      create: 'Venue Label erstellen',
      edit: 'Venue Label bearbeiten',
    },
    productCombos: {
      index: 'Product combos',
    },
    payment: {
      index: 'Bezahlung',
    },
    home: {
      index: 'Home',
    },
    register: {
      index: 'Registrierung',
      venue: 'Venues registrieren',
      success: 'Registrierung erfolgreich',
    },
    analytics: {
      index: 'Analytics',
    },
    sales: {
      index: 'Sales',
    },
    statistics: {
      index: 'Statistics',
    },
    error: {
      index: 'Fehler',
      notFound: '404 Page Not Found',
      forbidden: '401 Forbidden',
    },
    user: {
      index: 'User',
      create: 'User erstellen',
      edit: 'User bearbeiten',
      createSimple: 'User erstellen',
      editSimple: 'User bearbeiten',
    },
    role: {
      index: 'Role',
      create: 'Role erstellen',
      edit: 'Role bearbeiten',
    },
    foodcard: {
      index: 'Speisekarte importieren',
    },
    printer: {
      index: 'Printer',
      edit: 'Printer bearbeiten',
    },
    tag: {
      index: 'Tag',
      create: 'Create Tag',
      edit: 'Edit Tag',
    },
    constrain: {
      index: 'Tradegroup',
      create: 'Create Tradegroup',
      edit: 'Edit Tradegroup',
    },
    PrintGroups: {
      index: 'Printgroups',
      create: 'Create Printgroups',
      edit: 'Edit Printgroups',
    },
    dashboard: {
      index: 'Dashboard',
    },
    table: {
      index: 'Table',
      create: 'Create Table',
      edit: 'Edit Table',
    },
    category: {
      index: 'Category',
      create: 'Category erstellen',
      edit: 'Category bearbeiten',
    },
    subscriptionCategories: {
      index: 'Subscription Category',
      create: 'Subscription Category erstellen',
      edit: 'Subscription Category bearbeiten',
    },
    subscription: {
      index: 'Subscription',
      create: 'Subscription erstellen',
      edit: 'Subscription bearbeiten',
    },
    article: {
      index: 'Article',
      create: 'Article erstellen',
      edit: 'Article bearbeiten',
      editSimple: 'Article bearbeiten',
    },
    articleManager: {
      index: 'Artikelmanager',
    },
    option: {
      index: 'Option',
      create: 'Option erstellen',
      edit: 'Option bearbeiten',
      editSimple: 'Option bearbeiten',
    },
    recommendation: {
      index: 'Warenkorbempfehlung',
      create: 'Warenkorbempfehlung erstellen',
      edit: 'Warenkorbempfehlung bearbeiten',
    },
    workflow: {
      index: 'Workflow',
      create: 'Create Workflow',
      upload: 'Upload Workflow',
      edit: 'Edit Workflow',
      import: 'Import Workflow',
    },
    customer: {
      index: 'Customer',
      create: 'Create Customer',
      edit: 'Edit Customer',
      import: 'Import Customer',
    },
    userExport: {
      index: 'User export',
    },
    activityLogExport: {
      index: 'Aktivitätsprotokolle',
    },
    lightspeed: {
      index: 'Lightspeed',
      success: 'Erfolgreich autorisiert',
      error: 'Autorisierung fehlgeschlagen',
    },
    sync: {
      index: 'Sync',
    },
    inventory: {
      index: 'Inventar verwalten',
      create: 'Inventar erstellen',
      edit: 'Inventar bearbeiten',
    },
    forgotPassword: 'Passwort vergessen',
    resetPassword: 'Passwort zurücksetzen',
  },
  weekdays: {
    monday: 'Montag',
    mondayShort: 'Mon',
    tuesday: 'Dienstag',
    tuesdayShort: 'Die',
    wednesday: 'Mittwoch',
    wednesdayShort: 'Mit',
    thursday: 'Donnerstag',
    thursdayShort: 'Do',
    friday: 'Freitag',
    fridayShort: 'Fr',
    saturday: 'Samstag',
    saturdayShort: 'Sa',
    sunday: 'Sonntag',
    sundayShort: 'So',
  },
  orderStatus: {
    none: 'Default',
    created: 'Created',
    in_preparation: 'In Preparation',
    ready: 'Ready',
    in_payment: 'In Payment',
    awaiting_confirmation: 'Awaiting Confirmation',
    done: 'Done',
    failed: 'Failed',
    validated: 'Validated',
  },
  mainCategories: {
    dish: 'Essen',
    beverage: 'Getränk',
    other: 'Other',
  },
  autoReset: {
    none: 'None',
    morning: 'Morgen',
    noon: 'Mittag',
    evening: 'Abend',
    midnight: 'Miternacht',
  },
  customerGroups: {
    default: 'default',
    defaultInPreparation: 'default_in_preparation',
    subway: 'Subway',
    db: 'Deutsche Bahn',
    lunchroom: 'Lunchroom',
    weilands: 'Weilands',
    cinemaxx: 'Cinemaxx',
    fbf: 'Food By Friends',
    district: 'District',
    slush: 'Slush',
    kochloeffel: 'Kochlöffel',
    immergruen: 'Immergrün',
    myMonza: 'MyMonza',
    subwayFr: 'Subway Frankreich',
    subwayLu: 'Subway Luxemburg',
    subwayTerminal: 'Subway Terminal',
    aspasia: 'Aspasia',
    cotidiano: 'Cotidiano',
    bigburger: 'BigBurger',
    koos: 'KOOS',
    deanAndDavid: 'Dean and David',
    frittenwerk: 'Frittenwerk',
    purino: 'Purino',
    ottosBurger: 'Otto´s Burger',
    threeMensKebab: '3 Mens Kebab',
    perfectDay: 'Perfect Day',
    hausmanns: 'Hausmanns',
    doenerwerk: 'Dönerwerk',
    foodlounge: 'Foodlounge',
    mado: 'Mado',
    depot: 'Depot',
    nordsee: 'Nordsee',
    jamies: 'Jamies',
    atlantikFisch: 'Atlantik Fisch',
    moschmosch: 'Moschmosch',
    kamps: 'Kamps',
    coffeeFellows: 'Coffee Fellows',
    hofbrauhaus: 'Hofbräuhaus',
    saray: 'Saray',
    starbucks: 'Starbucks',
    dimatest: 'Dima Test',
    maloa: 'Maloa',
    werk5: 'Werk 5',
    epi: 'EPI',
    iamlove: 'IAMLOVE',
    beetsAndRoots: 'Beets and Roots',
    beetsAndRootsSecond: 'Beets and Roots 2',
    losteria: "L'Osteria",
    shiso: 'Shiso',
    snackElize: 'Snack Elize',
    tabilo: 'TuR_RES',
    tabiloSim: 'TuR_Simphony',
    turAbnahmeRes: 'TUR Abnahme RES',
    turAbnahmeSim: 'TUR Abnahme Sim',
    turAutohof: 'TUR Autohof',
    turAbnahmeAutohof: 'TUR Abnahme Autohof',
    effeGold: 'Effe & Gold',
    lacantine: 'La cantine',
    eam: 'Eam',
    naupaka: 'Naupaka',
    sportsbar: 'Sportsbar',
    cigkoftem: 'Cigkoftem',
    sushisupply: 'Sushi Supply',
    the_ash: 'The Ash',
    markthalle: 'Markthalle',
    sashimi: 'Sashimi',
    bonboncha: 'Bonboncha',
    hulala: 'Hulala',
    backwerk_schweiz: 'Backwerk Schweiz',
    backwerkCh: 'Backwerk CH',
    backfactory: 'Backfactory',
    teedeli: 'Teedeli',
    wohlfuhler: 'Wohlfuhler',
    asiaGourmet: 'Asia Gourmet',
    frittenwerkSecond: 'Frittenwerk 2',
    ricerepublic: 'RiceRepublic',
    goodbytz: 'Goodbytz',
    piratenBurger: 'Piraten Burger',
    tournesol: 'Tournesol',
    brezelKoenig: 'Brezelkönig',
    vincentVegan: 'Vincent Vegan',
    papais: 'Papais',
    theCup: 'The Cup',
    marseilleFoodMarket: 'Marseille Food Market',
    olearys: 'Olearys',
    iceland: 'Iceland',
    tableDot: 'Table Dot',
    sevenByBat: 'Seven by BAT',
    breakBurger: 'Break Burger',
    wok: 'Wok',
    oakberry: 'Oakberry',
    trz: 'TRZ',
    monkeyBurger: 'Monkey Burger',
    pastaFee: 'Pasta Fee',
    potatoe: 'Potatoe',
    pibose: 'Pibosa',
  },
  error: {
    notFound: 'Die Seite konnte leider nicht gefunden werden.',
    forbidden: 'Fehlende Rechte.',
    goHome: 'Zurück zum Start',
    forbiddenText: 'Fehlende Rechte.',
    notFoundText: 'Seite konnte nicht gefunden werden.',
  },
  foodcard: {
    index: 'Foodcard verwalten',
    export_csv: 'Foodcard exportieren (*.csv)',
    export_csv_de: 'DE Foodcard exportieren (*.csv)',
    import: 'Foodcard importieren',
    importCvs: 'Lebensmittelkarte importieren (*.csv)',
    importNutritionCsv: 'Ernährung importieren (*.csv)',
    export_xlsx_de: 'DE Foodcard exportieren (*.xlsx)',
    importCvsWithArticleInfo: 'Informationen zu Artikeln importieren (*.csv)',
  },
  home: {
    index: 'Home',
    welcome: 'Willkommen!',
  },
  tag: {
    index: 'Manage Tags',
    overview: 'Overview',
    add: 'Add Tag',
    empty:
      // tslint:disable-next-line:max-line-length
      "It looks like you haven't added any tags yet. Create your first tag to assign them to your articles and options.",
    form: {
      name: 'Name',
      identifier: 'Identifer',
      recommendationTag: 'Empfehlungstag',
    },
    table: {
      name: 'Name',
      identifier: 'Identifier',
      recommendationTag: 'Empfehlungstag',
    },
  },
  role: {
    index: 'Roles bearbeiten',
    overview: 'Übersicht',
    create: 'Role erstellen',
    edit: 'Role bearbeiten',
    empty: "It looks like you haven't created any roles yet. Create your first role to give users granular access.",
    help: 'System Roles können nicht mehr geändert werden.',
    add: 'Role hinzufügen',
    table: {
      name: 'Name',
      slug: 'Slug',
      isSystem: 'System Role',
      permissions: 'Permissions',
    },
    form: {
      name: 'Name',
      slug: 'Slug (unique identifier)',
      isSystem: 'System role (if active, role cannot be changed.)',
      permissions: 'Permissions',
    },
  },
  venueLabel: {
    index: 'Venue Labels bearbeiten',
    overview: 'Übersicht',
    create: 'Venue Label erstellen',
    edit: 'Venue Label bearbeiten',
    empty: "It looks like you haven't created any venue labels yet.",
    add: 'Label hinzufügen',
    table: {
      name: 'Name',
      slug: 'Slug',
      venues: 'Venues',
    },
    form: {
      name: 'Name',
      slug: 'Slug (unique identifier)',
      venues: 'Venues',
    },
  },
  recommendation: {
    index: 'Warenkorbempfehlung',
    create: 'Warenkorbempfehlung erstellen',
    edit: 'Warenkorbempfehlung bearbeiten',
    overview: 'Übersicht',
    score: 'Wert',
    scoreMatrix: 'Werte-Matrix',
    scoreMatrixNotFound: 'Werte-Matrix nicht gefunden',
    best: 'Bestwert',
    worst: 'Minimalwert',
  },
  user: {
    index: 'Users Verwalten',
    overview: 'Übersicht',
    create: 'User Erstellen',
    changePassword: 'Passwort ändern',
    help: 'Bitte bei der Bearbeitung von Usern vorsichtig sein.',
    edit: 'User bearbeiten',
    add: 'User Hinzufügen',
    table: {
      name: 'Name',
      email: 'E-Mail',
      code: 'Code',
      venues: 'Venues',
      role: 'Role',
    },
    form: {
      name: 'Name',
      email: 'E-Mail',
      password: 'Passwort',
      passwordHint:
        'The password must contain at least 8 characters, 1 capital letter and 1 number or special character.',
      customerGroup: 'Kundengruppe',
      passwordConfirmation: 'Passwort wiederholen',
      venues: 'Venues',
      venueLabels: 'Venue Labels',
      role: 'Role',
    },
  },
  printer: {
    index: 'Printer verwalten',
    overview: 'Übersicht',
    edit: 'Printer bearbeiten',
    customerGroup: 'Kundengruppe',
    empty: 'Aktuell liegen keine Printer vor.',
    table: {
      name: 'Name',
      articleCount: 'Artikelanzahl',
      venue: 'Venue',
      startedAt: 'Gestartet am',
      activated: 'Aktiviert',
      lastSignal: 'Letztes Signal',
      latestActivity: 'Letzte Aktivität',
      restart: 'Neustarten',
      restartInProgress: 'Wird neu gestartet...',
      order: 'Bestellung',
    },
    form: {
      articles: 'Articles',
      name: 'Name',
    },
  },
  customer: {
    index: 'Customers verwalten',
    overview: 'Übersicht',
    edit: 'Customer bearbeiten',
    create: 'Customer erstellen',
    add: 'Customer hinzufügen',
    import: 'Customer importieren',
    empty: 'Es liegen noch keine Kunden vor',
    table: {
      userUid: 'User UID',
      name: 'Name',
      email: 'E-Mail',
      qr: 'QR-Code',
    },
    form: {
      email: 'E-Mail',
      gender: 'Geschlecht',
      name: 'Name',
      city: 'Stadt',
      street: 'Straße',
      number: 'Nummer',
      postalCode: 'PLZ',
    },
  },
  workflow: {
    index: 'Manage Workflows',
    overview: 'Overview',
    add: 'Add Workflow',
    import: 'Import Workflow',
    empty:
      // tslint:disable-next-line:max-line-length
      "It looks like you havent' added any workflows yet. Create your first workflow to allow your customers to order.",
    table: {
      status: 'Status',
      code: 'Order Code',
      type: 'Order Type',
      venues: 'Venues',
    },
    form: {
      file: 'File',
    },
  },
  constrain: {
    index: 'Manage Tradegroups',
    overview: 'Overview',
    add: 'Add Tradegroup',
    empty:
      // tslint:disable-next-line:max-line-length
      "It looks like you haven't added any tradegroups yet. Create your first tradegroup to assign them to your articles.",
    form: {
      name: 'Name',
      identifier: 'Identifer',
      active: 'Active',
    },
    table: {
      name: 'Name',
      identifier: 'Identifier',
      active: 'Active',
    },
  },
  printGroups: {
    index: 'Manage Printgroups',
    overview: 'Overview',
    add: 'Add Printgroups',
    empty:
      // tslint:disable-next-line:max-line-length
      "It looks like you haven't added any tradegroups yet. Create your first Printgroups to assign them to your articles.",
    form: {
      name: 'Name',
      identifier: 'Identifer',
      active: 'Active',
    },
    table: {
      name: 'Name',
      identifier: 'Identifier',
      active: 'Active',
    },
  },
  languages: {
    de: 'Deutsch',
    en: 'Englisch',
    fr: 'Französisch',
    nl: 'Niederländisch',
    it: 'Italienisch',
    pl: 'Polnisch',
    es: 'Spanisch',
    tr: 'Türkisch',
    zh: 'Chinesisch',
    ru: 'Russisch',
    da: 'Dänisch',
    ja: 'Japanisch',
    is: 'Isländisch',
    pt: 'Portugiesisch',
    cz: 'Czech',
  },
  legalForms: {
    ag: 'AG',
    gmbh: 'GmbH',
    ltd: 'UG',
    kg: 'KG',
    pe: 'Einzelgesellschaft',
    other: 'Andere',
    sa: 'SA',
    sarl: 'SARL',
    eurl: 'EURL',
    sas: 'SAS',
    autres: 'AUTRES',
  },
  periods: {
    daily: 'Täglich',
    weekly: 'Wöchentlich',
    monthly: 'Monatlich',
    never: 'Kein Report',
  },
  locTypes: {
    bar: 'Bar',
    club: 'Club',
    restaurant: 'Restaurant',
    db: 'Deutsche Bahn',
    cinema: 'Kino',
    other: 'Other',
  },
  paymentTypes: {
    cc: 'Kreditkarte',
    pp: 'PayPal',
    sepa: 'SEPA',
    sb: 'Sofort Banking',
    bankTransfer: 'Überweisung',
  },
  nav: {
    dashboard: 'Dashboard',
    foodcard: 'Speisekarte',
    customer: 'Customers',
    venue: 'Venues',
    venueLabel: 'Venue Labels',
    payment: 'Payments',
    user: 'User',
    table: 'Tables',
    tag: 'Tags',
    sales: 'Sales',
    workflow: 'Workflows',
    printer: 'Printer',
    tradegroup: 'Tradegroups',
    printGroups: 'Printgroups',
    provision: 'Provision',
    inventory: 'Inventar',
    statistic: 'Statistics',
    analytic: 'Analytics',
    receipts: 'Receipts',
    billing: 'Billing',
    userExport: 'Benutzerexport',
    selectVenue: 'Venue auswählen',
    category: 'Kategorien',
    subscriptionCategories: 'Subscription Category',
    subscription: 'Subscription',
    article: 'Artikel',
    articleManager: 'Artikel- und Optionssichtbarkeit',
    recommendations: 'Empfehlungen',
    option: 'Optionen',
    sepa: 'SEPA',
    reporting: 'Reporting',
    import: 'Import',
    role: 'Role',
    tips: 'Tips',
    coupon: 'Coupon',
    activityLogExport: 'Aktivitätsprotokoll-Export',
    promocodesAndLoyalty: 'Aktionscodes und Treue',
    customerInfo: 'Kundeninformationen',
    other: 'Sonstiges',
    finances: 'Finanzen',
    analytics: 'Analytik',
    syncAndLogs: 'Synchronisation und Protokolle',
  },
  auth: {
    logout: 'Logout',
    login: 'Login',
    forgotPassword: 'Passwort vergessen',
    forgotPasswordHint: 'Bitte geben Sie Ihre E-Mail-Adresse ein und klicken Sie auf PASSWORT ZURÜCKSETZEN',
    resetPasswordHint:
      'Bitte geben Sie Ihr neues Passwort ein, wiederholen Sie dieses Eingabe und klicken Sie auf BESTÄTIGEN',
    resetPassword: 'Passwort zurücksetzen',
    reset: 'Passwort zurücksetzen',
    form: {
      password: 'Passwort',
      passwordConfirmation: 'Passwort wiederholen',
      email: 'E-Mail',
    },
    notification: {
      forgotPassword: 'Wir senden Ihnen einen Link zur Neuvergabe des Passworts an Ihre angegebene E-Mail.',
      resetPassword: 'Ihr Passwort wurde erfolgreich zurückgesetzt.',
      forbidden: 'Ihnen fehlen Zugriffsrechte.',
      login: 'Login erfolgreich!',
      logout: 'Logout erfolgreich.',
      resetPasswordError:
        'Zurücksetzen nicht erfolgreich. Bitte versuchen Sie es nochmal oder wenden Sie sich an den Support.',
    },
  },
  register: {
    index: 'Firmenanschrift',
    account: 'Konto',
    register: 'Registrierung',
    documentInfo:
      'Bitte füllen Sie noch die folgenden Daten aus, um Ihr Impressum, AGB und Datenschutzerklärung für Ihren Webshop zu generieren. Diese Dokumente sind notwendig.',
    success: 'Registrierung erfolgreich',
    addVenue: 'Venue hinzufügen',
    removeVenue: 'Venue entfernen',
    venue: 'Venue erstellen',
    successText:
      'Ihre Registrierung war erfolgreich. Sobald Ihre Venue angelegt wurde, werden wir Sie per Mail kontaktieren.',
    location: 'Restaurant Details',
    invoiceReceiver: 'Firmenanschrift',
    dialog: {
      venue: 'Wollen Sie Ihr Restaurant jetzt anlegen? Alternativ können Sie das auch nach dem Login vornehmen.',
    },
    form: {
      venue: 'Venue',
      name: 'Restaurantbezeichnung',
      firstName: 'Vorname',
      lastName: 'Nachname',
      phone: 'Telefonnummer',
      email: 'E-Mail',
      password: 'Passwort',
      passwordHint:
        'Das Passwort muss min. 8 Zeichen enthalten, einen Großbuchstaben und eine Ziffer oder Sonderzeichen.',
      passwordConfirmation: 'Passwort wiederholen',
      privacy: 'Hiermit bestätige ich die Datenschutzbestimmung der Sterlix GmbH.',
      privacyLink: 'Zur Datenschutzbestimmung',
    },
    notification: {
      venue:
        'Ihre Venue wird in wenigen Minuten erstellt. Bitte loggen Sie sich dann aus und wieder ein, um die neue Venue zu sehen. ',
    },
  },
  article: {
    index: 'Articles verwalten',
    create: 'Neuen Article hinzufügen',
    edit: 'Article bearbeiten',
    add: 'Article hinzufügen',
    copy: 'Artikel kopieren',
    overview: 'Übersicht',
    availability: 'Artikelverfügbarkeit',
    selectArticle: 'Artikel auswählen',
    selectCategory: 'Bitte Kategorie auswählen',
    general: 'Standard',
    standardAvailability: 'Standard Verfügbarkeit',
    terminalAvailability: 'Verfügbarkeiten Terminal',
    preorderAvailability: 'Verfügbarkeiten Vorbestellung',
    webAvailability: 'Web Verfügbarkeit',
    meta: 'Meta',
    images: 'Images',
    options: 'Options',
    mappings: 'Article Mappings',
    defaults: 'Article Defaults',
    depArticles: 'Articles',
    preorderPrices:
      'Abweichender Artikelpreis nach Preorder-Typ (Sollte hier kein Wert eingetragen sein, so wird der Standardpreis verwendet)',
    priceLockByType: "Preise sperren nach Bestellart (Preissperre unter 'GENERAL' hat Vorrang)",
    availableHours: 'Verfügbarkeitszeiten',
    addGroupDependency: 'Add Dependency',
    addGroupReverseDependency: 'Add Reverse Dependencies',
    addMappingArticle: 'Add Mapping Article',
    addDepArticles: 'Add Dependency Articles',
    addReverseDepArticles: 'Add Reverse Dependency Articles',
    dependencies: 'Dependencies',
    reverseDependencies: 'Reverse dependencies',
    addDefault: 'Add Default',
    split: 'Teilt',
    measurement: 'Measurement',
    price: 'Preis',
    priceLowToHigh: 'Niedrig bis hoch',
    priceHighToLow: 'Absteigend',
    visibility: 'Sichtbar',
    visible: 'Sichtbar',
    hide: 'Verstecken',
    fontColor: 'Schriftfarbe',
    styleOption: 'Stiloptionen',
    borderColor: 'Randfarbe',
    backgroundColor: 'Hintergrundfarbe',
    style: 'Styles',
    loyaltyOptions: 'Treueoptionen',
    openingHours: 'Öffnungszeiten',
    manufacturer: 'Hersteller',
    tabs: {
      general: 'General',
      images: 'Images',
      meta: 'Meta',
      options: 'Options',
      availableHours: 'Verfügbarkeitszeiten',
      allergens_and_additives: 'Allergene und Zusatzstoffe',
      nutritions: 'Ernährung',
      style: 'Styles',
      wolt: 'Wolt',
      uberEats: 'Uber-Eats',
      jet: 'Jet',
    },
    table: {
      name: 'Name',
      stock: 'Stock',
      price: 'Preis',
      active: 'Active',
      assets: 'Image',
      icon: 'Icon',
      banner: 'Banner',
      description: 'Beschreibung',
      number: 'Nummer',
      gtin: 'GTIN',
      visible: 'Visible',
      rowsPerPage: 'Rows Per Page',
      externalId: 'External Id',
      preorderDeliveryPrice: 'Lieferpreis vorbestellen',
      preorderTakeAwayPrice: 'Take-Away-Preis vorbestellen',
      terminalTakeAwayPrice: 'Terminal-Take-Away-Preis',
      terminalInsidePrice: 'Termina lInnenpreis',
    },
    form: {
      name: 'Name',
      description: 'Beschreibung',
      price: 'Preis',
      prices: 'Preise',
      type: 'Typ',
      value: 'Wert',
      emailDomain: 'E-Mail-Domain',
      replacesMainArticle: 'Replaces Main Article',
      gtin: 'GTIN (Global Trade Identifier Number)',
      stock: 'Stock',
      image: 'Image',
      unit: 'Einheit',
      amount: 'Wert',
      banner: 'Banner',
      icon: 'Icon',
      active: 'Active',
      tags: 'Tags',
      group: 'Optiongroup',
      enabledInFoodcard: 'Aktiv (wenn nicht aktiv, dann wird der Artikel nicht zur Wolt-Speisekarte hinzugefügt)',
      insidePrice: 'Artikelpreis für den Vor Ort Verzehr',
      deliveryPrice: 'Artikelpreis Lieferung',
      takeAwayPrice: 'Artikelpreis Abholungen',
      shortDescription: 'Kurzbeschreibung',
      articles: 'Articles',
      article: 'Article',
      code: 'Code',
      visible: 'Visible',
      mainRecommendations: 'Hauptartikel Empfelungen',
      printerSort: 'Printer Sort',
      quantity: 'Quantity',
      constrains: 'Tradegroups',
      printGroups: 'Printgroups',
      rateable: 'Rateable',
      taxInside: 'Tax Inside',
      taxTakeaway: 'Tax Takeaway',
      isControlArticle: 'Control Article',
      takeaway: 'Takeaway',
      delivery: 'Lieferung',
      inside: 'Inside',
      displayPrice: 'Display Price',
      isPrintable: 'Printable',
      isRateable: 'Rateable',
      info: 'Info',
      sort: 'Sort',
      mappedArticle: 'Mapped Article',
      category: 'Category',
      number: 'Number',
      deposit: 'Deposit',
      fsk: 'FSK',
      alcoholPercentage: 'Alkohol (in Prozent)',
      caffeineMg: 'Koffein (mg)',
      main: 'Main Article',
      kcal: 'Kcal',
      options: 'Options',
      standardAvailability: 'Verfügbar',
      insideAvailability: 'Verfügbar für den Vor Ort Verzehr',
      takeAwayAvailability: 'Verfügbar für Abholungen',
      deliveryAvailability: 'Verfügbar für Lieferung',
      webAvailability: 'Verfügbar',
      masterId: 'Master-ID',
      articleRelativeBrand: 'Brand',
      loyaltyId: 'Loyalty Id',
      isPiggyLoyalty: 'Is Piggy Loyalty',
      redeemableAtCheckoutOnly: 'Redeemable',
      piggyPointsNeeded: 'Piggy Points Needed',
      piggyRewardUuid: 'Piggy Reward Uuid',
      requiredLoyaltyPoints: 'Benötige Loyalty Punkte zur Einlösung',
      address: 'Adresse',
      country: 'Land',
      priceLockByType: {
        standard: 'Standard',
        terminal: 'Terminal',
        preorder: 'Preorder',
        inside: 'Preis für den Vor Ort Verzehr gesperrt',
        takeAway: 'Preis für Abholung gesperrt',
        delivery: 'Preis für Lieferung gesperrt',
      },
      allergenes: {
        allergenes: 'Allergene',
        cerealsWithGluten: 'Glutenhaltiges Getreide',
        crustaceans: 'Krebstiere und daraus gewonnene Erzeugnisse',
        eggs: 'Eier und daraus gewonnene Erzeugnisse',
        fish: 'Fische und daraus gewonnene Erzeugnisse',
        peanuts: 'Erdnüsse und daraus gewonnene Erzeugnisse',
        soya: 'Soja(bohnen) und daraus gewonnene Erzeugnisse',
        milk: 'Milch und daraus gewonnene Erzeugnisse',
        nuts: 'Schalenfrüchte',
        celery: 'Sellerie und daraus gewonnene Erzeugnisse',
        mustard: 'Senf und daraus gewonnene Erzeugnisse',
        sesame: 'Sesamsamen und daraus gewonnene Erzeugnisse',
        sulfur: 'Schwefeldioxid und Sulphite',
        lupins: 'Lupinen und daraus gewonnene Erzeugnisse',
        molluscs: 'Weichtiere und daraus gewonnene Erzeugnisse',
        corn: 'Mais und daraus gewonnene Erzeugnisse',
        wheat: 'Wheat and products thereof',
        rye: 'Weizen und daraus gewonnene Erzeugnisse',
        barley: 'Gerste und daraus gewonnene Erzeugnisse',
        oats: 'Hafer und daraus hergestellte Produkte',
        spelt: 'Dinkel und daraus gewonnene Erzeugnisse',
        khorasan: 'Khorasan und Produkte davon',
        walnuts: 'Walnüsse und daraus gewonnene Erzeugnisse',
        pecanNuts: 'Pekannüsse und daraus hergestellte Produkte',
        brazilNuts: 'Paranüsse und daraus hergestellte Produkte',
        pistachioNuts: 'Pistazien und daraus hergestellte Erzeugnisse',
        macadamiaNuts: 'Macadamianüsse und daraus hergestellte Produkte',
        almonds: 'Mandeln und daraus hergestellte Produkte',
        hazelnuts: 'Haselnüsse und daraus gewonnene Erzeugnisse',
        cashews: 'Cashewnüsse und daraus hergestellte Produkte',
        seeds: 'Sämereien und daraus hergestellte Erzeugnisse',
        poppySeeds: 'Mohnsamen und daraus hergestellte Produkte',
        sunflowerSeeds: 'Sonnenblumenkerne und daraus hergestellte Produkte',
        poultryMeat: 'Geflügelfleisch und daraus hergestellte Produkte',
        beef: 'Rindfleisch und daraus hergestellte Produkte',
        pigmeat: 'Schweinefleisch und daraus gewonnene Erzeugnisse',
        yeast: 'Hefeextrakt',
      },
      additives: {
        additives: 'Zusatzstoffe',
        1: 'mit Farbstoff',
        2: 'mit Konservierungsstoff',
        3: 'mit Antioxidationsmittel',
        4: 'mit Geschmacksverstärker',
        5: 'geschwefelt',
        6: 'geschwärzt',
        7: 'mit Phosphat',
        8: 'mit Milcheiweiß (bei Fleischerzeugnissen)',
        9: 'koffeinhaltig',
        10: 'chininhaltig',
        11: 'mit Süßungsmittel',
        12: 'gewachst',
        13: 'Neu',
        14: 'enthält Alkohol',
      },
      nutrition: {
        portionInG: 'Portionsgröße (g)',
        portionInML: 'Portionsgröße (ml)',
        energyKcalPerPortion: 'Energie (kcal)',
        energyKcalPer100G: 'Energie (kcal/100g)',
        energyKcalPer100ML: 'Energie (kcal/100ml)',
        energyKjPerPortion: 'Energie (kj)',
        energyKjPer100G: 'Energie (kj/100g)',
        energyKjPer100ML: 'Energie (kj/100ml)',
        fatPerPortion: 'Fett (g)',
        fatPer100G: 'Fett (g/100g)',
        fatPer100ML: 'Fett (g/100ml)',
        saturatesPerPortion: 'Gesättigt (g)',
        saturatesPer100G: 'Gesättigt (g/100g)',
        saturatesPer100ML: 'Gesättigt (g/100ml)',
        carbohydratePerPortion: 'Kohlenhydrate (g)',
        carbohydratePer100G: 'Kohlenhydrate (g/100g)',
        carbohydratePer100ML: 'Kohlenhydrate (g/100ml)',
        sugarPerPortion: 'Zucker (g)',
        sugarPer100G: 'Zucker (g/100g)',
        sugarPer100ML: 'Zucker (g/100ml)',
        proteinPerPortion: 'Eiweiß (g)',
        proteinPer100G: 'Eiweiß (g/100g)',
        proteinPer100ML: 'Eiweiß (g/100ml)',
        saltPerPortion: 'Salz (g)',
        saltPer100G: 'Salz (g/100g)',
        saltPer100ML: 'Salz (g/100ml)',
      },
      features: {
        basic: 'Basisch',
        features: 'Merkmale',
        vegan: 'Vegan',
        vegetarian: 'Vegetarier',
        glutenFree: 'Glutenfrei',
        noCarb: 'Kohlenhydratfrei',
        alkaline: 'Alkalisch',
        lactoseFree: 'Laktosefrei',
        new: 'Neu',
        special: 'Aktion',
        topSelling: 'Top Seller',
        warm: 'Warm',
        cold: 'Kalt',
        fish: 'Fisch',
        chicken: 'Geflügel',
        beef: 'Rind ',
        pork: 'Schwein',
        prepackaged: 'Vorgepackt',
      },
    },
  },
  articleManager: {
    index: 'Artikel- und Optionssichtbarkeit',
    overview: 'Übersicht',
    mainArticle: 'Hauptartikel',
    optionArticle: 'Optionsartikel',
    options: 'Optionen',
    deactivateAll: 'Alle deaktivieren',
    activateAll: 'Alle aktivieren',
    onlyOne: 'Nein, nur diesen',
    filter: {
      category: 'Kategorie wählen',
      status: 'Status des Artikels wählen',
      type: 'Artikel oder Optionsartikel',
      search: 'Suche',
      allStatus: 'Alle',
      active: 'Sichtbar',
      notActive: 'Unsichtbar',
      article: 'Artikel',
      options: 'Optionen',
    },
    table: {
      visibility: 'Sichtbarkeit',
      name: 'Name',
      category: 'Kategorie',
      options: 'Optionen',
      price: 'Preis',
      optionGroup: 'Optionsgruppe',
      connectedArticles: 'Verbundene Artikel',
      description: 'Beschreibung',
      loadMore: 'Mehr laden',
    },
    notification: {
      saved: 'Änderungen gespeichert.',
      sameArticleNumber:
        'Wir haben Artikel mit derselben Artikelnummer gefunden. Möchten Sie alle aktivieren/deaktivieren (abhängig von der Aktion) oder nur diesen?',
    },
  },
  translation: {
    start: 'Übersetzung gestartet. Dies kann bis zu 10 Minuten dauern',
  },
  payment: {
    index: 'Payments',
    overview: 'Übersicht',
    refundArticle: 'Refund Article',
    refundOrder: 'Refund Order',
    refundQuestion: 'Sind Sie sicher, dass Sie zurückerstatten wollen? Diese Aktion kann nicht widerrufen werden.',
    refunded: 'Is part of a refund',
    order: 'Order',
    refund: 'Zurückerstattet',
    searchCode: 'Suche nach Bestellcode...',
    methods: {
      credit_card: 'Kreditkarte',
      sepa: 'SEPA',
      sofortbanking: 'Sofort',
      paypal: 'PayPal',
      google_pay: 'Google Pay',
      apple_pay: 'Apple Pay',
    },
    table: {
      sum: 'Summe',
      status: 'Status',
      hasRefunds: 'Refunds',
      codes: 'Codes',
      method: 'Zahlmethode',
      createdAt: 'Datum',
    },
  },
  option: {
    index: 'Options bearbeiten',
    create: 'Option hinzufügen',
    edit: 'Option bearbeiten',
    add: 'Option hinzufügen',
    unlinkedOptions: 'Nicht an Artikel gebundene Optionen',
    empty:
      "It looks like you haven't added any options yet. Create your first option group to make even better and customizable articles.",
    overview: 'Übersicht',
    tabs: {
      general: 'Allgemein',
      articles: 'Artikel',
      features: 'Merkmale',
      availableHours: 'Verfügbarkeitszeiten',
      compound: {
        features: 'Merkmale',
        new: 'Neu',
        special: 'Aktion',
        topSelling: 'Top Seller',
      },
    },
    articles: {
      table: {
        name: 'Name',
        description: 'Beschreibung',
        price: 'Preis',
        assets: 'Images',
        active: 'Active',
        visible: 'Visible',
        number: 'Nummer',
      },
      addTitle: 'Add article',
      editTitle: 'Edit article',
    },
    table: {
      name: 'Name',
      description: 'Beschreibung',
      requiredAmount: 'Required Amount',
      limit: 'Limit',
      hasMultiple: 'Has Multiple',
      sortOrder: 'Sort',
      visible: 'Visible',
      article: 'Artikel',
    },
    form: {
      name: 'Name',
      description: 'Beschreibung',
      shortDescription: 'Kurzbeschreibung',
      image: 'Image',
      limit: 'Limit',
      active: 'Active',
      visible: 'Visible',
      virtual: 'Virtual',
      sort: 'Sort',
      printerSort: 'Printer Sort',
      requiredAmount: 'Required Amount',
      hasMultiple: 'Multiple',
      displayIdentifiers: 'Display Identifiers',
      displayMode: 'Display Mode',
      displayModeSot: 'Display Mode SOT',
      displayModeMobile: 'Display Mode Mobile',
      tags: 'Tags',
      printGroups: 'Print Groups',
    },
    deleteHeader: 'Optionen löschen',
    deleteInfo: 'Möchten Sie diese Optionen wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.',
    deleteSelected: 'Ausgewählte löschen',
  },
  category: {
    index: 'Categories verwalten',
    create: 'Category anlegen',
    edit: 'Category bearbeiten',
    add: 'Category hinzufügen',
    overview: 'Übersicht',
    availableHours: 'Verfügbarkeitszeiten',
    table: {
      name: 'Name',
      sort: 'Sort',
      mainCategory: 'Main Category',
      visible: 'Visible',
    },
    form: {
      name: 'Name',
      description: 'Beschreibung',
      mainCategory: 'Main Category',
      sort: 'Sort',
      displayMode: 'Display Mode',
      mainRecommendations: 'Main Recommendations',
      displayIdentifiers: 'Display Identifiers',
      visible: 'Visible',
      image: 'Image',
      icon: 'Icon',
    },
  },
  subscriptionCategories: {
    index: 'Subscription Categories verwalten',
    create: 'Subscription Category anlegen',
    edit: 'Subscription Category bearbeiten',
    add: 'Subscription Category hinzufügen',
    overview: 'Übersicht',
    table: {
      name: 'Name',
      sort: 'Sort',
      mainCategory: 'Main Subscription Subscription Category',
    },
    form: {
      name: 'Name',
      description: 'Beschreibung',
      image: 'Image',
      subscriptions: 'Subscriptions',
      venues: 'Venues',
      canCombine: 'Kombinieren',
      isActive: 'Aktiv',
    },
  },
  subscription: {
    index: 'Subscription verwalten',
    create: 'Subscription anlegen',
    edit: 'Subscription bearbeiten',
    add: 'Subscription hinzufügen',
    overview: 'Übersicht',
    table: {
      name: 'Name',
      sort: 'Sort',
      mainCategory: 'Main Subscription',
    },
    form: {
      name: 'Name',
      description: 'Beschreibung',
      image: 'Image',
      useMasterFoodcard: 'Verwenden Sie die Master Foodcard',
      maxPricePerOrder: 'Maximaler Preis pro Bestellung',
      maxPricePerArticle: 'Höchstpreis pro Artikel',
      maxArticlesPerOrder: 'Max. Artikel pro Bestellung',
      maxOrdersPerDay: 'Maximale Bestellungen pro Tag',
      minimumOrderOffset: 'Subscription Order Offset',
      isActive: 'Aktiv',
      automaticallyRenew: 'Automatisch erneuern',
      tax: 'Steuer',
      articles: 'Articles',
      venue: 'Venue',
      linkedCustomerGroup: 'Verknüpfte Kundengruppe',
      price: 'Preis',
      factor: 'Laufzeit',
      unit: 'Einheit',
      default: 'Standart',
    },
  },
  membership: {
    index: 'Mitgliedschaft verwalten',
    create: 'Erstellen Sie eine neue Mitgliedschaft',
    edit: 'Mitgliedschaft bearbeiten',
    add: 'Mitgliedschaft hinzufügen',
    overview: 'Übersicht',
    table: {
      name: 'Name',
    },
    form: {
      name: 'Name',
      description: 'Beschreibung',
      image: 'Image',
      multiplier: 'Multiplikator',
      neededPoints: 'Benötigte Punkte',
      piggyId: 'Piggy Id',
      customerGroup: 'Kundengruppe',
    },
    tier: {
      membershiptier1: 'membershiptier1',
      membershiptier2: 'membershiptier2',
      membershiptier3: 'membershiptier3',
      membershiptier4: 'membershiptier4',
      membershiptier5: 'membershiptier5',
    },
  },
  table: {
    index: 'Manage Tables',
    create: 'Create a new Table',
    exportQrs: 'Export QR-Codes',
    edit: 'Edit Table',
    add: 'Add Table',
    overview: 'Overview',
    table: {
      number: 'Number',
      description: 'Description',
      active: 'Active',
      tags: 'Tags',
      qr: 'QR-Code',
      sanifairDeviceId: 'Sanifair Geräte-ID',
    },
    form: {
      number: 'Number',
      description: 'Description',
      sanifairDeviceId: 'Sanifair Geräte-ID',
    },
  },
  venue: {
    index: 'Venues verwalten',
    create: 'Venue hinzufügen',
    panic: 'Panic',
    createSimple: 'Venue erstellen',
    copyFoodcard: 'Speisekarte kopieren',
    editMasterId: 'Master-ID bearbeiten',
    overview: 'Übersicht',
    ppConnected: 'PayPal Konto Status',
    ppConnectedSuccess: 'PayPal-Konto erfolgreich verbunden',
    ppConnectedError: 'PayPal-Konto nicht verbunden oder fehlgeschlagen',
    lightspeedAuthorize: 'Lightspeed Autorisieren',
    lightspeedAuthorized: 'Lightspeed erfolgreich autorisiert',
    lightspeedNotAuthorized: 'Lightspeed ist nicht verbunden',
    generateSlugAuto: 'Slug automatisch generieren',
    add: 'Venue hinzufügen',
    createSepa: 'Neuen SEPA Link erstellen',
    testOrder: 'Testbestellung senden',
    registerPayPal: 'PayPal Konto verbinden',
    deliveryFees: 'Liefergebühren',
    deliveryFeesRadius: 'Liefergebühren nach Radius',
    deliveryFeesPostalCode: 'Liefergebühren nach Postleitzahl',
    walleeCredentials: 'Wallee-Anmeldeinformationen',
    walleePaymentConfs: 'Wallee-Zahlung',
    edit: 'Venue bearbeiten',
    deleteFoodcard: 'Speisekarte löschen',
    customerSupportMail: 'Kundendienst',
    panicInfo: 'Information : Für die Venue {venue} ist bis {panicEndAt} Uhr PANIC aktiviert.',
    notification: {
      testOrderSuccess: 'Die Testbestellung war erfolgreich.',
      testOrderError:
        'Die Testbestellung ist fehlgeschlagen. Bitte überprüfen Sie Ihre Einstellungen. Falls Sie weiterhin Probleme haben, kontaktieren Sie einen Administrator.',
      sendInvoiceSuccess: 'Rechnung wurde erfolgreich versendet.',
      createCreditNoteSuccess: 'Gutschrift wurde erfolgreich angelegt.',
      registerPayPalError: 'Konnte PayPal-Autorisierung nicht ausführen.',
      sendInvoiceError: 'Versenden der Rechnung ist fehlgeschlagen.',
      createCreditNoteError: 'Erstellen der Gutschrift ist fehlgeschlagen.',
      successfulCopy: 'Option was copied',
      webAppSlug:
        'Sind Sie sicher, dass Sie das Web App Slug-Feld generieren möchten? In diesem Fall wird dies für alle Veranstaltungsorte durchgeführt.',
      copyRunning: 'Venue kopieren gestartet.',
      copyError: 'Kopieren der Venue fehlgeschlagen. Bitte die Logs überprüfen.',
      deleteSuccess: 'Speisekarte wurde erfolgreich gelöscht!',
      sepa: {
        createSuccess: 'Neuer SEPA-Mandat Registrierungslink wurde erfolgreich erstellt',
        createError: 'Erstellen eines neuen SEPA-Mandat Registrierungslinks fehlgeschlagen',
        paySuccess: 'Neue SEPA-Zahlung wurde erfolgreich erstellt',
        payError: 'Erstellen einer neuen SEPA-Zahlung fehlgeschlagen',
      },
    },
    confirmation: {
      verify: {
        title: 'Venue bestätigen',
        text: 'Hiermit bestätigen Sie, dass alle Preise, Artikel und Venue-Einstellungen richtig sind. Des Weiteren haben Sie die Dokumente AGB, Impressum und Datenschutz hochgeladen.',
        action: 'Verify Venue',
      },
      publish: {
        title: 'Publish Confirmation',
        text: 'Hiermit bestätigen Sie, dass alle Preise, Artikel und Venue-Einstellungen richtig sind.',
        action: 'Publish Venue',
      },
      deleteFoodcard: {
        title: 'Bist du sicher, dass die gesamte Speisekarte gelöscht werden soll?',
        text: 'Es werden alle Kategorien, Artikel, Optionsgruppen, Optionsartikel, und Tags gelöscht!<br/><br/>Diese Aktion kann nicht rückgängig gemacht werden',
      },
    },
    tooltipCannotSync: 'Synchronisierung läuft bereits. Bitte warten Sie oder kontaktieren Sie einen Administrator',
    verify: 'Verify',
    publish: 'Publish',
    credentials: 'Credentials',
    payment: 'Payment',
    order: 'Order',
    preorder: 'Preorder',
    slotting: 'Slot Settings',
    documents: 'Dokumente',
    invoices: {
      createCreditNote: 'Gutschrift anlegen',
      createCreditNoteNet: 'Gutschrift anlegen (Netto)',
      send: 'Rechnung senden',
      transactions: 'Transaktionen',
      balance: 'Saldo',
      type: 'Rechnungsart',
      refOrNote: 'Rechnungsreferenz / Notiz',
      createdAt: 'Erstellt am',
      amount: 'Betrag',
      setupFee99: 'Setupgebühr (99€)',
      setupFee49: 'Setupgebühr (49€)',
      premiumPrinterPackage: 'Premium Drucker Paket',
      printerTablet: 'Tablet mit integriertem Belegdrucker (319,00 + 8,00 €)',
      printerTablet200: 'Tablet mit integriertem Belegdrucker (200€ + 8€)',
      printerTablet230: 'Tablet mit integriertem Belegdrucker (230€ + 8€)',
      provision: 'Provision',
      creditNoteNet: 'Gutschrift (Netto)',
      status: 'Status',
      sepa: {
        debit: 'SEPA-Lastschrift',
        createWarning:
          'Sind Sie sich sicher, einen neuen SEPA-Mandat Registrierungslink zu erstellen? Existierendes SEPA-Mandat wird entfernt und kann nicht mehr für Zahlungen verwendet werden.',
        payWarning:
          'Es existiert ein SEPA-Lastschriftmandat für diese Venue. Möchten Sie eine neue SEPA-Zahlung für diese Transaktion erstellen?',
      },
    },
    ci: 'Checkout Integration',
    general: 'Allgemein',
    details: 'Details',
    contact: 'Kontakt',
    whiteLabel: 'whiteLabel',
    invoiceReceiver: 'Kontaktdaten',
    address: 'Addresse',
    settings: 'Einstellungen',
    sizeSettings: 'Size Settings',
    orderOffsets: 'Zeitmanagement',
    preorderOffsets: 'Preorder Offsets',
    uberDelivery: 'Uber Delivery',
    miscellaneous: 'Sonstiges',
    preorderPayment: 'Preorder Payment',
    deliveryPayment: 'Preorder Delivery Payment',
    sepaCredentials: 'SEPA Credentials',
    ccCredentials: 'CreditCard Credentials',
    ppCredentials: 'PayPal Credentials',
    sbCredentials: 'SofortBanking Credentials',
    openingHours: 'Öffnungszeiten',
    deliveryHours: 'Lieferzeiten',
    sanifair: 'Sanifair Einstellungen',
    boardingPass: 'Boarding Pass',
    datev: 'Datev',
    locTypes: {
      bar: 'Bar',
      club: 'Club',
      restaurant: 'Restaurant',
      db: 'Deutsche Bahn',
      cinema: 'Kino',
      other: 'Other',
    },
    tabs: {
      general: 'Allgemein',
      payment: 'Payment',
      terminal: 'Terminal',
      preorder: 'Preorder',
      ci: 'CheckoutIntegration',
      order: 'Order',
      slotting: 'Slotting',
      documents: 'Dokumente',
      openingHours: 'Öffnungszeiten',
      deliveryHours: 'Lieferzeiten',
      deliveryProviders: 'Lieferanbieter',
      orderProviders: 'Bestellanbieter',
      invoices: 'Rechnungen',
      externalDeliveryService: 'Externer Lieferdienst',
      sanifair: 'Sanifair',
      salesforce: 'Salesforce',
      delfi: 'Delfi',
      loyalty: 'Loyalty',
      boardingPass: 'Boarding Pass',
      datev: 'Datev',
    },
    table: {
      name: 'Name',
      shortId: 'Short-ID',
      email: 'E-Mail',
      street: 'Straße',
      number: 'Hausnummer',
      city: 'Stadt',
      legalForm: 'Unternehmensform',
      locType: 'Typ',
      vatNumber: 'USt-IdNr.',
      vatNumberGermany: 'Steuernummer',
      customerGroup: 'Kundengruppe',
      isServiceActivated: 'Dienst aktiviert',
      checkoutIntegration: 'Checkout Integration',
      piggyLoyaltyEnabled: 'Loyalty',
      isPublished: 'Published',
      masterId: 'Master-ID',
    },
    form: {
      image: 'Bild',
      sapNumber: 'SAP Nummer',
      name: 'Restaurantbezeichnung',
      newName: 'Neue Restaurantbezeichnung',
      asSlave: 'Erstelle Venue als Slave Venue von der ausgewählten Venue',
      restaurantToken: 'Smoothr Restaurant Token',
      fullName: 'Firmenname',
      masterId: 'Master-ID',
      currency: 'Währung',
      sourceVenue: 'Quell-Venue',
      targetVenue: 'Ziel-Venue',
      uberCustomerId: 'Uber Customer Id',
      uberDeliveryEnabled: 'Uber Delivery Enabled',
      externalDeliveryService: {
        name: 'Name',
        link: 'Link',
        icon: 'Icon',
      },
      secondaryCodeEnabled: 'Sekundärcode Aktiviert',
      preorderTakeAwayOffsets: 'Zeitvorlauf Abholungen',
      preorderDeliveryOffsets: 'Zeitvorlauf Lieferung',
      preorderInsideOffsets: 'Zeitvorlauf Vor Ort Verzehr',
      calculateDays: 'Count of days to calculate slots',
      deliveryPostalCodes: 'Liefergebiet nach Postleitzahlen bestimmen',
      deliveryPostalCodesHint:
        'Bitte geben Sie hier eine Postleitzahl und zugehörige Stadt an, ggf. auch eine Straße. (Bsp. 50679 Köln, Alsenstraße )',
      country: 'Land',
      locType: 'Location Type',
      deliveryFee: 'Liefergebühr in {currency} (falls nicht weiter spezifiert)',
      deliveryByRadius: 'Liefern nach Lieferradius (ansonsten nach PLZ-Gebieten)',
      provisionRate: 'Provisionsrate',
      panicEndAt: 'Panic (Deaktiviert den Eingang von Bestellungen)',
      provisionFixedRate: 'Fixer Provisionssatz',
      readableId: 'Short-ID',
      deliveryRadius: 'Lieferradius',
      deliveryRadiusMax: 'Lieferradius (max)',
      isPublished: 'Published',
      deliveryHours: 'Lieferzeiten',
      active: 'Active',
      virtualOrders: 'Allow Virtual Orders',
      stock: 'Stock',
      movDelivery: 'Mindestbestellwert in {currency}',
      checkoutIntegration: 'Checkout Integration',
      printerIntegration: 'Printer Integration',
      readyOffset: 'Preparation Offset',
      readyOffsetDelivery: 'Preparation Offset Delivery',
      interval: 'Intervallgröße',
      reserved: 'Reservierte Bestellungen',
      max: 'Maximale Anzahl von Bestellungen',
      notPickedOffset: 'Not picked offset',
      isServiceActivated: 'Service Status (Haken entfernen, um Restaurant Service offline zu setzen)',
      smoothrDispatchEnabled: 'Smoothr Dispatch',
      isCallingSystemEnabled: 'Aufrufsystem',
      inventoryEnabled: 'Inventarisierung Aktiviert',
      legalForm: 'Unternehmensform',
      email: 'E-Mail',
      ppEmail: 'PayPal Konto E-Mail',
      orderEmail: 'Order E-Mail',
      storeEmail: 'Store E-Mail',
      web: 'Website',
      pickupBoxKey: 'Pickup Box Key',
      pickupBoxDeviceId: 'Pickup Box Device Id',
      pickupBoxAppId: 'Pickup Box App Id',
      pickupBoxCredentials: 'Pickup Box Credentials',
      standardOrders: 'Allow Standard Orders',
      preorderTakeAway: 'Abholungen/Pickup anbieten',
      preorderParkCollect: 'Park & Collect anbieten',
      preorderFoodspot: 'Foodspot anbieten',
      preorderIn: 'In House Bestellungen anbieten',
      preorderDelivery: 'Lieferung anbieten',
      autoAccept: 'Auto Accept in',
      autoAcceptTerminal: 'Auto Accept Terminal Order in',
      checkoutIn: 'Checkout In',
      printIn: 'Print In',
      autoReset: 'Auto Reset In',
      orderTimeout: 'Bestellzeitüberschreitung',
      orderTimeoutEnabled: 'Order-Timeout aktivieren',
      phone: 'Telefon',
      city: 'Stadt',
      area: 'Nearest City',
      street: 'Straße',
      streetAddress: 'Straße und Hausnummer',
      number: 'Hausnummer',
      postalCode: 'Postleitzahl',
      federalState: 'Bundesland',
      timezone: 'Zeitzone',
      googlePlacesId: 'Google Places ID',
      customerGroup: 'Customer Group',
      sendProvisionReportPeriod: 'Abrechnungsrhythmus',
      lastInvoiceNumber: 'Last invoice number',
      sendProvisionReportPeriodNonInvoiceable: 'Send Provision Report (without invoice number)',
      vatNumber: 'Umsatzsteuer-Identifikationsnummer (USt-IdNr.)',
      vatNumberQuestion: 'USt-IdNr. verwenden?',
      vatNumberGermany: 'Steuernummer (z.B. 12/345/67890)',
      vatNumberGermanyQuestion: 'Keine USt-IdNr.?',
      mov: 'MBW',
      from: 'Ab MBW von X {currency}',
      fee: 'Gebühr X {currency}',
      secureCode: 'Secure Code',
      selfCertification: 'Self Certification',
      reviewHyperlink: 'Überprüfen Sie den Hyperlink',
      pdfUpload: 'PDF-File hochladen',
      insertLink: 'Link einfügen',
      nutritionTable: 'Nährwerttabelle',
      gpsManualInput: 'Manuelle Eingabe (GPS-Koordinaten)',
      gpsCorrect: 'GPS-Koordinaten korrekt?',
      latitude: 'Latitude',
      longitude: 'Longitude',
      paymentEnabled: 'Payment Enabled',
      sepaEnabled: 'SEPA Enabled',
      sbEnabled: 'SofortBanking Enabled',
      ppEnabled: 'PayPal Enabled',
      orderPrinterTabletPackageInfo:
        'Ich möchte ein Tablet mit integriertem Belegdrucker für das automatische Empfangen von Bestellungen für {price}€ (netto) bestellen. Alternativ werden die Bestellungen per Email versendet.',
      ccEnabled: 'CreditCard Enabled',
      posEnabled: 'POS Enabled',
      cashEnabled: 'Cash Enabled',
      secretKey: 'Secret Key',
      merchantName: 'Merchant Name',
      creditorId: 'Creditor Id',
      merchantId: 'Merchant Id',
      userId: 'User Id',
      spaceId: 'Space Id',
      userKey: 'User Key',
      reka: 'Reka',
      twint: 'Twint',
      boncard: 'Boncard',
      luncheck: 'Luncheck',
      postfinance: 'Postfinance',
      postfinanceCard: 'Postfinance Card',
      wechat: 'Wechat',
      alipay: 'Alipay',
      worldline: 'Worldline',
      articleSpanCount: 'Article Span Count',
      categorySpanCount: 'Category Span Count',
      fullWidthCategories: 'Categories On full Width',
      showInactiveArticles: 'Show Interactive Articles',
      askForTableNumber: 'Ask For Table Number',
      foodAndBeveragesSeparated: 'Food And Beverages Separated',
      toolbarTitleHidden: 'Toolbar Title Hidden',
      displayCirclePickerDescription: 'Display Circle Picker Description',
      dailyUnsnoozeTime: 'Artikel Offset',
      toolbarCategoryListVisible: 'Toolbar Category List Visible',
      toolbarSidesVisible: 'Toolbar Sides Visible',
      multiLanguage: 'Multi language',
      printQr: 'Print QR',
      rekaEnabled: 'Reka aktiviert',
      twintEnabled: 'Twint aktiviert',
      walleeEnabled: 'Wallee aktiviert',
      alipayEnabled: 'Alipay aktiviert',
      wcEnabled: 'WeChat Pay aktiviert',
      bcEnabled: 'Boncard aktiviert',
      lchEnabled: 'Luncheck aktiviert',
      cwlEnabled: 'Worldline aktiviert',
      ccvEnabled: 'CCV aktiviert',
      zeroPaymentEnabled: 'Nullzahlung aktiviert',
      woltPaymentEnabled: 'Woltzahlung aktiviert',
      uberEatsPaymentEnabled: 'Uber-Eats-Zahlung aktiviert',
      jetPaymentEnabled: 'JET-Zahlung aktiviert',
      ctCreditCardPaymentEnabled: 'Computop Kreditkarte aktiviert',
      bankTransferPaymentEnabled: 'Überweisung aktiviert',
      sapCCOEnabled: 'SAP CCO aktiviert',
      pfEnabled: 'PostFinance E-finance aktiviert',
      pfcEnabled: 'PostFinance Card aktiviert',
      firstOrderOffset: 'Frühestmögliche Bestellung nach Öffnung',
      firstOrderOffsetHint:
        'Bitte geben Sie hier an, ab wie vielen Minuten nach Ladenöffnung eine Bestellung eingehen darf, 60 = 1 Std',
      orderOffset: 'Min. Minutenanzahl zwischen Bestelleingang und Ausführung',
      lastOrderOffset: 'Letztmögliche Bestellung vor Schließung',
      lastOrderOffsetHint:
        'Bitte geben Sie hier an, wie vielen Minuten vor Ladenschließung eine Bestellung eingehen darf, 60 = 1 Std',
      orderOffsetHint: 'Bitte geben Sie den Zeitvorlauf in Minuten an (bspw. 60 entspricht 1 Stunde)',
      preorderOffset: 'Frühestmögliche Bestellung nach Öffnung',
      preorderOffsetHint:
        'Bitte geben Sie hier an, ab wie vielen Minuten nach Ladenöffnung eine Bestellung eingehen darf, 60 = 1 Std',
      firstPreorderOffset: 'Frühestmögliche Bestellzeitpunkt für den Kunden auf Basis der Uhrzeit vom Kunden',
      lastPreorderOffset: 'Letztmögliche Bestellung vor Schließung',
      lastPreorderOffsetHint:
        'Bitte geben Sie hier an, wie vielen Minuten vor Ladenschließung eine Bestellung eingehen darf, 60 = 1 Std',
      autoDeclineTime: 'Cancel time',
      uploadedFile: 'Hochgeladene Datei',
      terms: 'AGB',
      imprint: 'Impressum',
      privacy: 'Datenschutz',
      registerCourt: 'Registergericht',
      registerNumber: 'Registernummer',
      chiefExecutives: 'Der/die Geschäftsführer',
      sepaMandateRef: 'SEPA-Mandat Referenz',
      sepaMandateRegistrationLink: 'SEPA-Mandat Registrierungslink',
      sepaMandateRegistrationError: 'SEPA-Mandat Registrierungsfehler',
      deliveryProviders: {
        orderLordEnabled: 'OrderLord aktiviert',
        orderLordActive: 'OrderLord verfügbar (vom Administrator gesetzt)',
        orderLordToken: 'OrderLord API Token',
        orderLordPassword: 'OrderLord API Passwort',
        rushhourIntegration: 'Rush Hour Integration aktiviert',
        rushhourToken: 'Rush Hour Token',
      },
      externalOrderProviders: {
        useExternalProviders: 'Externe Bestellanbieter verwenden',
        orderDirectEnabled: 'OrderDirect aktiviert',
        orderDirectImprint: 'OrderDirect Impressum',
        woltEnabled: 'Wolt aktiviert',
        connectWolt: 'Get onto Wolt',
        connectUberEats: 'Get onto Uber Eats',
        syncAllFoodcards: 'Speisekarten synchronisieren',
        uberEatsEnabled: 'UberEats aktiviert',
        jetEnabled: 'JET aktiviert',
        apiKey: 'Api-Key',
        venue: 'Venue-ID',
        username: 'Benutzername',
        password: 'Passwort',
        orderApiKey: 'Order-Api-Key',
        syncFoodcard: 'Speisekarte synchronisieren',
        syncStatus: 'Venue Status synchronisieren',
        syncOpeningHours: 'Öffnungszeiten synchronisieren',
        foodcardSyncStarted:
          'Die Speisekartensynchronisierung wurde zur Warteschlange hinzugefügt und wird in Kürze gestartet.',
        online: 'Die Venue wurde aktiv geschaltet. Bestellungen sind nun möglich',
        offline: 'Die Venue wurde deaktiviert. Bestellungen sind sind nicht möglich.',
        openingHoursSynched: 'Die Öffnungszeiten wurden synchronisiert.',
        preparationTime: 'Durchschnittliche Zubereitungszeit',
        deliveryTime: 'Durchschnittliche Lieferzeit',
        autoAccept: 'Bestellungen automatisch akzeptieren',
        ocuFlowEnabled: 'OCU Flow',
        clientId: 'Client-Id',
        clientSecret: 'Client-Secret',
        checkoutIntegrationDelayingEnabled: 'Kassenintegration (POS) verzögern',
        autoSyncEnabled: 'Speisekarte automatisch synchronsieren',
        ignorePosStatusEvents: 'POS Events ignorieren',
        autoSyncEnabledHint:
          'Die Speisekarte wird in der Regel alle ~2 Stunden synchronisiert, insofern Änderungen vorgenommen wurden.',
      },
      invoiceReceiver: {
        email: 'Alternative Rechnungsempfänger E-Mail-Adresse (ansonsten wird die Kontakt E-Mail verwendet)',
      },
      deliveryFeesAfterCreation: 'Liefergebühren können nach Erstellung in den Venue-Einstellungen angegeben werden.',
      sanifairEnabled: 'Sanifair-Integration',
      sanifairLocationId: 'Sanifair-Location-Id',
      sanifairDeviceId: 'Sanifair-Device-Id',
      addExternalDelivery: 'Externen Lieferservice hinzufügen',
      deleteExternalDelivery: 'Löschen',
      customerSupportMail: {
        email: 'E-Mail',
      },
      sandboxUrl: 'Sandbox Url',
      clientId: 'ClientId',
      clientSecret: 'Client Secret',
      username: 'Username',
      password: 'Password',
      recordType: 'Record Type',
      channelPc: 'Channel Pc',
      enabled: 'Enabled',
      syncMenu: 'Sync Menu',
      ccvApikey: 'ccvApikey',
      comoApiKey: 'comoApiKey',
      boardingPassVerificationEnabled: 'Boarding-Pass validieren',
      paxControlScanningPositionId: 'Pax Control Scanning Position Id',
      costCenter: 'Kostenstelle',
      account: 'Konto',
      offsetAccount: 'Gegenkonto',
    },
    legal: {
      useTemplate: 'Vorlage verwenden',
      termsHtml: `<h1>ALLGEMEINE GESCHÄFTSBEDINGUNGEN</h1><p></p>
      <h2><strong>1. Vertragsabschluss</strong></h2><ol><li><p>Die Darstellung der Produkte in unserem Online-Shop stellt kein rechtlich bindendes Angebot, sondern einen unverbindlichen Katalog der von uns angebotenen Produkte dar. Wenn Sie eine Bestellung mit einem Klick auf den Bestellbutton aufgeben, unterbreiten Sie uns das Angebot zum Abschluss eines entsprechenden Vertrages. Die Annahme erfolgt mit erfolgreicher Durchführung der Bezahlung Ihrer Bestellung oder mit unserer ausdrücklichen Annahme Ihres Angebotes. Eine von uns automatisiert versandte E-Mail, die den Eingangs Ihrer Bestellung bestätigt, stellt keine Annahme Ihres Angebotes dar.</p></li><li><p>Sie haben während der Eingabe Ihrer Bestellung die Möglichkeit, Korrekturen an den eingegebenen Daten vorzunehmen. Bitte nutzen Sie hierfür die „Zurück-Funktion“ Ihres Browsers oder die von uns angegebenen Möglichkeiten.</p></li></ol>
      <h2>2. Vertragssprache, Speicherung des Vertragstextes und Korrektur eingegebener Daten</h2><ol><li><p>Vertragssprache ist Deutsch.</p></li><li><p>Der Vertragstext wird bei uns nicht gespeichert und kann nach Abschluss des Bestellvorgangs nicht mehr durch Sie abgerufen werden. Sie erhalten mit Absenden der Bestellung von uns eine Bestätigungsemail, welche den Inhalt Ihrer Bestellung und diese AGB in Textform enthält.</p></li></ol>
      <h2>3. Mängelansprüche</h2><ol><li><p>Sofern der Kunde Unternehmer im Sinne von § 14 BGB, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen ist, entscheiden wir, ob wir den Mangel durch Nachbesserung oder Neulieferung beheben.</p></li><li><p>Im Übrigen und für Verbraucher gelten die gesetzlichen Regelungen.</p></li></ol>
      <h2>4. Haftung</h2><ol><li><p>Die Haftung für Vorsatz und grobe Fahrlässigkeit ist unbeschränkt.</p></li><li><p>Bei einfach fahrlässiger Verletzung wesentlicher Vertragspflichten ist die Haftung der Höhe nach beschränkt auf vorhersehbare und vertragstypische Schäden. Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung die verletzte Partei regelmäßig vertrauen darf.</p></li><li><p>Sofern der Kunden Unternehmer im Sinne von § 14 BGB, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen ist, beträgt die Verjährungsfrist für Ansprüche nach Absatz 2 ein Jahr.</p></li><li><p>Absatz 2 gilt nicht für Ansprüche aus der Verletzung des Körpers, der Gesundheit oder des Lebens, bei arglistigem Handeln, bei Übernahme einer Garantie, bei Haftung für anfängliches Unvermögen oder zu vertretender Unmöglichkeit sowie für Ansprüche nach dem Produkthaftungsgesetz.</p></li></ol>
      <h2>5. Nichtbestehen eines Widerrufrechts</h2><p>Bei den von uns angebotenen Produkten handelt es sich um Lebensmittel, die schnell verderben können. Es besteht daher gemäß § 312 Abs. 2 Nr. 2 BGB kein Widerrufsrecht.</p>
      <h2>6. Außergerichtliche Streitschlichtung</h2><ol><li><p>Die EU-Kommission hat unter der Adresse <a href="http://ec.europa.eu/consumers/odr/" rel="noopener noreferrer nofollow">http://ec.europa.eu/consumers/odr/</a> eine Plattform für außergerichtliche Streitschlichtung bereitgestellt.</p></li><li><p>Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.</p></li></ol>
      <h2>7. Schlussbestimmungen</h2><ol><li><p>Sollte eine Bestimmung dieser Geschäftsbedingungen ganz oder teilweise nichtig, unwirksam oder nicht durchsetzbar sein oder werden, oder sollte eine an sich notwendige Regelung nicht enthalten sein, bleibt die Wirksamkeit und die Durchsetzbarkeit aller übrigen Bestimmungen dieses Vertrages unberührt.</p></li><li><p>Sofern der Kunde Verbraucher im Sinne von § 13 BGB ist, gilt folgendes: Der Vertrag unterliegt allein dem Recht der Bundesrepublik Deutschland, allerdings nur insoweit, als dem Kunden nicht der Schutz entzogen wird, der ihm durch zwingende Bestimmungen des Staates gewährt wird, in dem er im Zeitpunkt der Aufgabe der Bestellung seinen gewöhnlichen Aufenthalt hat. Sofern der Kunde keinen allgemeinen Gerichtsstand in Deutschland oder in einem anderen EU-Mitgliedstaat hat, ist ausschließlich Gerichtsstand für sämtliche Streitigkeiten aus diesem Vertrag an unserem Sitz.</p></li><li><p>Sofern der Kunde Unternehmer im Sinne von § 14 BGB, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen ist, ist alleiniger Gerichtsstand für alle Streitigkeiten im Zusammenhang mit dieser Vereinbarung an unserem Sitz.</p></li></ol><p></p>`,
      imprintHtml: `<h1>Impressum des Franchisenehmers</h1><p></p>
      <h2>Angaben gemäß § 5 TMG</h2><p>{{company}}</p><p>{{street}}</p><p>{{postalCode}} {{city}}</p><p>Handelsregister: {{registerNumber}}</p><p>Registergericht: {{registerCourt}}</p><p></p>
      <h2>Vertreten durch den/die Geschäftsführer:</h2><p>{{chiefExecutives}}</p><p></p>
      <h2>Kontakt</h2><p>Telefon: {{phone}}</p><p>E-Mail: {{email}}</p><p>FAX: {{fax}}</p><p></p>
      <h2>Umsatzsteuer</h2><p>Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: {{vatNumber}}</p><p></p>
      <h2>Streitschlichtung</h2><p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr." rel="noopener noreferrer nofollow">https://ec.europa.eu/consumers/odr.</a> Unsere E-Mail-Adresse finden Sie oben im Impressum. Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p><p></p>
      <h2>Informationen zum Betreiber</h2><p>Die Sterlix GmbH ist für die Inhalte der Bestellseite nicht verantwortlich! Bei Fragen zu Ihrer Bestellung, Produkten/Menüs, Zusatzstoffe oder Nährwerte, wenden Sie sich bitte direkt an das o.g. Restaurant.</p>
      <p>Das Impressum des Betreibers finden Sie unter folgendem Link: <a href="https://smoothr.de" rel="noopener noreferrer nofollow">https://smoothr.de</a></p><p></p>`,
      privacyHtml: `<h1>Datenschutzerklärung</h1>
      <h3><strong>Verantwortlicher</strong></h3><p>{{company}}</p><p>{{street}}</p><p>{{postalCode}} {{city}}</p><p>Telefon: {{phone}}</p><p>E-Mail: {{email}}</p><p>Fax: {{fax}}</p>
      <h3><strong>Fragen zum Datenschutz und zur Ausübung Ihrer Rechte</strong></h3>
      <p>Wenn Sie Fragen zum Datenschutz haben oder die Ihnen zustehenden Datenschutzrechte ausüben möchten, kontaktieren Sie uns bitte über die oben angegebenen Kontaktdaten.</p>
      <h3><strong>Bestellung unserer Produkte</strong></h3>
      <p>Mit Ihrer Bestellung verarbeiten wir die von Ihnen angegebenen Daten im erforderlichen Umfang für die Durchführung des mit Ihnen geschlossenen Vertrages. Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 b) DSGVO.</p><p>Aufgrund gesetzlicher Vorgaben für Bestellung im Internet sind wir verpflichtet, Ihnen eine elektronische Bestellbestätigung zu versenden. Rechtsgrundlage hierfür ist Artikel 6 Abs. 1 c) DSGVO. Soweit im Zusammenhang mit der Dokumentation des Abschlusses und der Durchführung des mit Ihnen abgeschlossenen Vertrages rechtliche Aufzeichnungs- und Aufbewahrungspflichten (z.B. Aufbewahrung des Nachweises des Vertragsabschlusses oder von Rechnungen) bestehen, ist Rechtsgrundlage der entsprechenden Verarbeitung Art. 6 Abs. 1 c) DSGVO.</p><p>Wir verarbeiten Ihre Daten außerdem zur Erkennung und Abwehr von Betrugsversuchen auf Grundlage von Art. 6 Abs. 1 f) DSGVO. Wir verfolgen damit das Ziel, uns vor betrügerischen Transaktionen zu schützen.</p><p>Daten, die im Zusammenhang mit dem Abschluss eines Vertrages über den Kauf eines Produktes gespeichert werden, werden nach Ablauf der gesetzlichen Aufbewahrungspflicht gelöscht.</p>
      <p><strong>Bezahlvorgang</strong></h3>
      <p>Die Abwicklung der Bezahlung Ihrer Bestellung erfolgt durch PayPal (Europe) S.à r.l. et Cie, S.C.A. 22-24 Boulevard Royal L-2449 Luxembourg durchgeführt. PayPal ist für die Datenverarbeitung zuständig.</p>
      <h3><strong>Kontaktanfragen</strong></h3>
      <p>Wenn Sie uns über eine der angebotenen Kontaktmöglichkeiten eine Mitteilung zukommen lassen, verwenden wir Ihre uns mitgeteilten Daten für die Bearbeitung Ihrer Anfrage. Rechtsgrundlage hierfür ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 f) DSGVO. Sofern Ihre Anfrage dem Abschluss eines Vertrages mit uns dient, ist weitere Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 b) DSGVO. Die Daten werden nach Erledigung Ihrer Anfrage gelöscht. Sofern wir gesetzlich zu einer längeren Speicherung verpflichtet sein sollten, erfolgt die Löschung nach Ablauf der entsprechenden Frist.</p>
      <h3><strong>Ihre Rechte</strong></h3>
      <p>Ihnen stehen im Zusammenhang mit Ihren personenbezogenen Daten aus der DSGVO insbesondere die nachfolgend genannten Rechte zu. Wegen der Details verweisen wir auf die gesetzlichen Regelungen.</p>
      <h3><strong>Recht auf Auskunft</strong></h3>
      <p>Nach Art. 15 DSGVO haben Sie das Recht, von uns eine Bestätigung darüber zu verlangen, ob durch uns Sie betreffende personenbezogene Daten verarbeitet werden. Wenn dies der Fall ist, so haben Sie ein Recht auf Auskunft über diese personenbezogenen Daten und auf weitergehende Informationen, die in Art. 15 DSGVO genannt sind.</p>
      <h3><strong>Recht auf Berichtigung</strong></h3>
      <p>Nach Art. 16 DSGVO haben Sie das Recht, von uns unverzüglich die Berichtigung unrichtiger personenbezogener Daten zu verlangen, die Sie betreffen.&nbsp;Unter Berücksichtigung der Zwecke der Verarbeitung haben Sie ferner das Recht, die Vervollständigung unvollständiger personenbezogener Daten – auch mittels einer ergänzenden Erklärung – zu verlangen.</p>
      <h3><strong>Recht auf Löschung</strong></h3>
      <p>Sie haben das Recht von uns zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden. Wir sind verpflichtet, personenbezogene Daten unverzüglich zu löschen, sofern die entsprechenden Voraussetzungen des Art. 17 DSGVO vorliegen. Wegen der Einzelheiten verweisen wir auf Art. 17 DSGVO.</p>
      <h3><strong>Recht auf Einschränkung der Verarbeitung</strong></h3>
      <p>Nach Maßgabe des Art. 18 DSGVO haben Sie unter bestimmten Voraussetzungen das Recht, von uns die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</p>
      <h3><strong>Recht auf Datenübertragbarkeit</strong></h3>
      <p>Unter den Voraussetzungen des Art. 20 DSGVO haben Sie das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Nach Art. 20 DSGVO haben Sie weiter das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln, sofern die Verarbeitung auf einer Einwilligung gemäß Artikel 6 Abs. 1 a) DSGVO oder Artikel 9 Abs. 2 a) DSGVO oder auf einem Vertrag gemäß Artikel 6 Abs. 1 b) DSGVO beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</p>
      <h3><strong>Bestehen eines Beschwerderechts bei der Aufsichtsbehörde</strong></h3>
      <p>Sie haben nach Art. 77 DSGVO unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht, sich bei der Aufsichtsbehörde zu beschweren. Dieses Recht besteht insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.</p>
      <h3><strong>Widerspruchsrecht</strong></h3>
      <p><strong>Nach Art. 21 DSGVO haben Sie das Recht gegen die Verarbeitung Sie betreffender personenbezogener Daten, die auf Grundlage von&nbsp;Artikel 6&nbsp;Absatz 1 Buchstaben e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.</strong></p>
      <p><strong>Sofern wir Ihre personenbezogenen Daten verarbeiten, um Direktwerbung zu betreiben, haben Sie jederzeit das Recht, gegen die Verarbeitung sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung Widerspruch einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</strong></p>
      <p></p>`,
    },
    infoOpeningHours: {
      openingHours: `- Du kannst Öffnungszeiten für einzelne Tage hinzufügen, indem du einen Tag und ein Zeitfenster auswählst . Klicke danach auf das Uhrensymbol, um die Öffnungszeiten zu speichern.<br />
      - Durch die gleichzeitige Auswahl mehrerer Wochentage kannst du für diese Tage die gleichen Öffnungszeiten hinzufügen. Um die Öffnungszeiten zu speichern, klicke auf das Uhrensymbol.<br />
      - Du kannst auch mehrere Öffnungszeiten für einen Tag hinzufügen, indem du die Schritte zum Anlegen einzelner Öffnungszeiten wiederholst.`,
      specialOpeningHours: `- Wenn du möchtest, dass dein Restaurant an einem bestimmten Tag (z. B. Feiertag) besondere Öffnungszeiten hat, kannst du diese hier hinzufügen. Wähle einfach ein Datum und füg die besonderen Öffnungszeiten hinzu.<br />
      - Wenn dein Restaurant an einem bestimmten Datum geschlossen sein soll, wähle den Tag aus und wähle „Restaurant geschlossen“. Das Restaurant bleibt den ganzen Tag geschlossen.<br />
      - Andernfalls, wenn dein Restaurant an einem bestimmten Datum für eine bestimmte Zeit geschlossen sein soll, wähle das Datum und ein Zeitfenster aus und klicke auf „Restaurant geschlossen“. Das Restaurant bleibt zu diesem bestimmten Zeitpunkt geschlossen.`,
    },
  },
  analytic: {
    from: 'Von',
    to: 'Bis',
  },
  stats: {
    index: 'Statistics verwalten',
    indexAnalytics: 'Analytics verwalten',
    totalNumberOfOrders: 'Anzahl Bestellungen',
    avgOrdersPerDay: 'Ø Anzahl Bestellung/Tag',
    avgAmount: 'Ø Bestellwert',
    totalAmount: 'Gesamtsumme',
    totalSoldArticles: 'Anzahl verkaufte Artikel',
    highestDaysSale: 'Höchster Tagesumsatz',
    totalPreCheckouts: 'Bestätigte Precheckouts',
    paymentMethod: 'Bezahlmethode',
    avgOrderDuration: 'Ø Durchschnittliche Bestelldauer',
    totalTimeouts: 'Anzahl Timeouts',
    monthSales: 'Monatsumsatz',
    selectVenue: 'Venue auswählen',
    customerGroup: 'Kundengruppe',
    ui: {
      search: 'Suche',
      bestSold: 'Bestsellers',
      worstSold: 'Worstsellers',
      ordersView: 'Orders view',
      articleView: 'Article view',
      quantity: 'Anzahl',
      orders: 'Bestellungen',
      price: 'Preis',
      refresh: 'Aktualisieren',
      debug: 'TESTING',
      prod: 'LIVE',
      flavor: 'Select Flavor(s)',
      status: 'Status auswählen',
      table: 'Type(s) auswählen',
      type: 'Table(s) auswählen',
      label: 'Venue Label auswählen',
      searchId: 'Such ID',
      searchCode: 'Such code',
      number: 'Nummer',
      copyOrderId: 'Auftragsnummer',
      today: 'Heute',
      yesterday: 'Gestern',
      monthly: 'Monatlich',
      daily: 'Täglich',
    },
    table: {
      order: 'Bestellung',
      paymentMethod: 'Bezahlmethode',
      date: 'Datum',
      time: 'Zeit',
      venue: 'Venue',
      qty: 'Anzahl',
      itemName: 'Items',
      allCat: 'All Categories',
      cat: 'Categories',
      type: 'Order Type',
      status: 'Status',
      table: 'Table',
      price: 'Preis/Article',
      total: 'Total',
      name: 'Name',
      note: 'Note',
      secondaryCode: 'Sekundärcode',
      dndCompanyCode: 'Firmencode',
    },
  },
  sales: {
    index: 'Product Sales',
    ui: {
      category: 'Kategorie(n) auswählen',
      item: 'Item(s) auswählen',
      label: 'Venue Label auswählen',
      dateType: 'Datumsart',
      createdAt: 'Bestelldatum',
      orderAt: 'Abhol-/Lieferdatum',
    },
    table: {
      name: 'Name',
      sales: 'Sales',
      total: 'Total',
      cat: 'Category',
    },
  },
  billing: {
    index: 'Abrechnung',
    billing: 'Abrechnung exportieren',
    orderCsv: 'Bestellungen exportieren (Der Export wird an die Email gesendet mit der Sie gerade eingeloggt sind.)',
    paymentMethodCsv:
      'Bestellungen nach Zahlungsart exportieren (Der Export wird an die Email gesendet mit der Sie gerade eingeloggt sind.)',
    salesCsv: 'Übersicht verkaufte Artikel (Der Export wird an die Email gesendet mit der Sie gerade eingeloggt sind.)',
    salesGroup:
      'Verkaufsgruppe exportieren (Der Export wird an die Email gesendet mit der Sie gerade eingeloggt sind.)',
    summary: 'Umsätze exportieren ',
    plainSummary: 'Abrechnungsübersicht exportieren',
    articlesOverview: 'Art der Bestellung',
    form: {
      label: 'Venue Label',
      venue: 'Venue',
      customerGroup: 'Kundengruppe',
      flavor: 'Flavor',
      withoutTags: '<empty> Tags nicht filtern',
      subscribedToMailing: 'Newsletter abonniert',
      filterEmptyByMail: 'Filter per Mail leer',
      typeExportFile: 'Typ der exportierten Datei',
      requiredField: 'Bitte wählen Sie eines der Felder aus',
    },
  },
  userExport: {
    index: 'Benutzerexport',
    userExport: 'Benutzerexport',
    userCsv: 'Benutzer exportieren',
    userWithNewsletterCsv: 'Benutzer mit Newsletter exportieren',
    form: {},
  },
  activityLogExport: {
    index: 'ActivityAktivitätsprotokoll-Export Log Export',
    activityLogCsv: 'Protokolle exportieren',
    form: {
      type: 'Typ',
      venue: 'Venue',
      typeExportFile: 'Typ der exportierten Datei',
    },
  },
  validations: {
    unique_email: 'Die E-Mail wird bereits verwendet',
    readable_id: 'Die Short-ID wird bereits verwendet.',
    password: 'Min. 8 Zeichen, einen Großbuchstaben und eine Ziffer oder Sonderzeichen.',
    list_of_emails: 'Die Emails müssen mit einem Komma getrennt werden.',
    numbers_with_comma_or_dot: 'Das Feld {field} muss eine Zahl sein.',
    date_before: 'Das {field} Feld muss vor {target} sein',
    unique_dependency: 'Das {field} ist ein Duplikat',
    smaller_than: '{field} muss kleiner als {target} sein.',
    greater_than: '{field} muss größer als {target} sein.',
    greater_eq_than: '{field} muss mindestens {target} sein.',
    uppercase: 'Nur Großbuchstaben erlaubt',
    price: 'Das {field} muss ein gültiger Preis sein (z.B. 1.99)',
    nutritional_value: 'Das {field} muss ein gültiger Preis Nährwert (z.B. 1.99)',
    uniqueFile: 'Die hochgeladenen Medien können nicht geändert werden. Bitte erstelle einen neuen Schritt.',
    url: 'Bitte geben Sie eine gültige Domain ohne http/https ein, nur die Domain und die TLD.',
    vatNumber: {
      info: 'Gültige USt-IdNr. mit mindestens 5 Zeichen erforderlich',
      error:
        'USt-IdNr. ist ungültig oder wird bereits von einem anderen Benutzer verwendet. Bitte geben Sie eine andere Nummer ein oder kontaktieren Sie einen Administrator, um eine Lösung für das Problem zu finden.',
    },
    vatNumberGermany: {
      info: 'Gültige Steuernummer mit mindestens 5 Zeichen erforderlich',
      error:
        'Steuernummer ist ungültig oder wird bereits von einem anderen Benutzer verwendet. Bitte geben Sie eine andere Nummer ein oder kontaktieren Sie einen Administrator, um eine Lösung für das Problem zu finden.',
    },
  },
  promo: {
    index: 'PromoCodes verwalten',
    create: 'Neuen PromoCode erstellen',
    edit: 'PromoCode bearbeiten',
    overview: 'Übersicht',
    add: 'PromoCode hinzufügen',
    disableCodes: 'PromoCode deaktivieren',

    disableHint: 'Bitte eine Venue auswählen',
    notification: {
      disableSuccess: 'Disabled promo codes successfully.',
    },
    types: {
      absolute: 'Rabatt (fester Betrag)',
      relative: 'Rabatt (Prozentsatz)',
      bogo: 'BOGO',
      deliveryFee: 'Versandkostenfrei',
      giftCard: 'Gift Card',
      reward: 'Reward',
      freeArticle: 'Kostenloser Artikel',
      void: 'Leer',
      absoluteArticle: 'Artikel Rabatt (fester Betrag)',
      relativeArticle: 'Artikel Rabatt (Prozentsatz)',
      mostExpensiveArticleDiscountRelative: 'Größter Artikelrabatt (Prozentsatz)',
      teedeliPremium: 'Artikel Rabatt (Prämie)',
    },
    table: {
      name: 'Name des PromoCodes',
      code: 'Code',
      customerGroup: 'Kundengruppe',
      mov: 'Mindestbestellwert',
      from: 'Gültig von',
      to: 'Gültig bis',
      disabled: 'Deaktiviert (Venue only)',
      venues: 'Venues',
      type: 'Typ',
      value: 'Wert',
      active: 'Aktiv (Global)',
      countries: 'Länder',
      states: 'Bundesländer',
      amount: 'Max. Verwendbarkeit des PromoCodes',
    },
    form: {
      offerPeriod: 'Angebotszeitraum',
      lightspeedCode: 'Lightspeed Code',
      name: 'Name des PromoCodes',
      code: 'Code',
      venue: 'Venue',
      valid: 'Gültig',
      customerGroup: 'Kundengruppe',
      countries: 'Länder',
      states: 'Bundesländer',
      article: 'Artikel',
      isMultipleApplicable: 'Kunden können den PromoCode mehrmals verwenden',
      applyOnAnyQuantity: 'Auf jede beliebige Menge anwenden',
      uploadGiftCards: 'CSV file with Gift Cards',
      codes: 'Code',
      venues: 'Venues',
      type: 'Typ',
      label: 'Venue Label',
      freeArticle: 'Kostenloser Artikel',
      bogoArticles: 'Artikel (günstigstes Produkt aus dem Warenkorb wird verwendet)',
      mov: 'Mindestbestellwert',
      amount: 'Max. Verwendbarkeit des PromoCodes',
      active: 'Aktiv',
      value: 'Wert',
      percent: 'Wert (für z.B. 20% bitte 0.2 eintragen)',
      discount: 'Rabatt',
      standardAvailability: 'Verfügbar',
      insideAvailability: 'Verfügbar für den Vor Ort Verzehr',
      takeAwayAvailability: 'Verfügbar für Abholungen',
      deliveryAvailability: 'Verfügbar für Lieferung',
      preorderParkCollect: 'Verfügbar für Park & Collect',
      preorderFoodspot: 'Verfügbar für Foodspot',
      webAvailability: 'Verfügbar',
    },
    tabs: {
      general: 'Allgemein',
      availability: 'Verfügbarkeit',
    },
    standardAvailability: 'Standard Verfügbarkeit',
    terminalAvailability: 'Verfügbarkeiten Terminal',
    preorderAvailability: 'Verfügbarkeiten Vorbestellung',
    webAvailability: 'Web Verfügbarkeit',
    weekdaysAvailability: 'Wochentage Verfügbarkeit',
    cateringAvailability: 'Catering-Verfügbarkeit',
  },
  cupCode: {
    index: 'Cup-Code verwalten',
    create: 'Erstellen Sie einen neuen Cup-Code',
    edit: 'Pokalcode bearbeiten',
    overview: 'Übersicht',
    add: 'Pokalcode hinzufügen',
    downloadCodes: 'Exportcodes',
    downloadQRCode: 'Laden Sie QR-Codes herunter',
    action: 'Aktionen für eine Reihe von Codes',
    form: {
      number: 'Nummer',
      venue: 'Venue',
      active: 'Aktiv',
      quantity: 'Anzahl der zu generierenden Codes',
      startingFrom: 'Startanzahl der Codes',
      customerGroup: 'Kundengruppe',
      format: 'Formatdatei auswählen',
    },
    table: {
      number: 'Nummer',
      venue: 'Venue',
      customerGroup: 'Kundengruppe',
      quantity: 'Menge',
      startingFrom: 'Starting From',
      active: 'Aktiv',
      qrCode: 'QR Code',
      createdAt: 'Erstellt',
    },
    filter: {
      customerGroup: 'Kundengruppe',
      active: 'Aktiv',
      numFrom: 'Nummer von',
      numTo: 'Nummer bis',
    },
  },
  coupon: {
    index: 'Gutschein verwalten',
    create: 'Erstellen Sie einen neuen Gutschein',
    edit: 'Gutschein bearbeiten',
    overview: 'Übersicht',
    add: 'Gutschein hinzufügen',
    disableCodes: 'Gutschein deaktivieren',
    disableHint: 'Bitte eine Venue auswählen',
    notification: {
      disableSuccess: 'Coupon erfolgreich deaktiviert.',
    },
    form: {
      offerPeriod: 'Angebotszeitraum',
      lightspeedCode: 'Lightspeed Code',
      name: 'Name',
      code: 'Code',
      venue: 'Venue',
      valid: 'Gültig',
      customerGroup: 'Kundengruppe',
      countries: 'Länder',
      states: 'Bundesländer',
      article: 'Artikel',
      isMultipleApplicable: 'Kunden können den PromoCode mehrmals verwenden',
      uploadGiftCards: 'CSV file with Gift Cards',
      codes: 'Code',
      venues: 'Venues',
      type: 'Typ',
      label: 'Venue Label',
      freeArticle: 'Kostenloser Artikel',
      bogoArticles: 'Artikel (günstigstes Produkt aus dem Warenkorb wird verwendet)',
      mov: 'Mindestbestellwert',
      amount: 'Max. Verwendbarkeit des PromoCodes',
      active: 'Aktiv',
      value: 'Wert',
      percent: 'Wert (für z.B. 20% bitte 0.2 eintragen)',
      discount: 'Rabatt',
      standardAvailability: 'Verfügbar',
      insideAvailability: 'Verfügbar für den Vor Ort Verzehr',
      takeAwayAvailability: 'Verfügbar für Abholungen',
      deliveryAvailability: 'Verfügbar für Lieferung',
      preorderParkCollect: 'Verfügbar für Park & Collect',
      preorderFoodspot: 'Verfügbar für Foodspot',
      webAvailability: 'Verfügbar',
      usageFrequency: 'Nutzungshäufigkeit',
    },
    types: {
      absolute: 'Absolute Value',
      relative: 'Relative Value',
      bogo: 'BOGO',
      deliveryFee: 'No Delivery Free',
      reward: 'Reward',
      giftCard: 'Gift Card',
      freeArticle: 'Free Article',
      void: 'Void',
      absoluteArticle: 'Article Discount (absolute)',
      relativeArticle: 'Article Discount (relative)',
      plusOneFree: '1 + 1 Free',
    },
    usageFrequency: {
      any: 'Beliebig',
      daily: 'Täglich',
      weekly: 'Wöchentlich',
      monthly: 'Monatlich',
    },
    table: {
      name: 'Name',
      code: 'Code',
      customerGroup: 'Kundengruppe',
      mov: 'Mindestbestellwert',
      from: 'Gültig von',
      to: 'Gültig bis',
      disabled: 'Deaktiviert (Venue only)',
      venues: 'Venues',
      type: 'Typ',
      value: 'Wert',
      active: 'Aktiv (Global)',
      countries: 'Länder',
      states: 'Bundesländer',
      amount: 'Max. Verwendbarkeit des PromoCodes',
    },
    tabs: {
      general: 'Allgemein',
      availability: 'Verfügbarkeit',
    },
    standardAvailability: 'Standard Verfügbarkeit',
    terminalAvailability: 'Verfügbarkeiten Terminal',
    preorderAvailability: 'Verfügbarkeiten Vorbestellung',
    webAvailability: 'Web Verfügbarkeit',
    weekdaysAvailability: 'Wochentage Verfügbarkeit',
    cateringAvailability: 'Catering-Verfügbarkeit',
  },
  paypal: {
    index: 'PayPal',
    success: 'PayPal-Autorisierung',
    description:
      'PayPal-Autorisierung war erfolgreich. Nachdem unsere Mitarbeiter Ihre Angaben überprüft haben, wird PayPal für Ihre Venue aktiviert.',
  },
  footer: {
    imprint: 'Impressum',
    terms: 'Datenschutzrichtlinie',
  },
  sepa: {
    index: 'SEPA',
    title: 'SEPA Einstellungen',
    mandate: 'Mandate',
    sepaMandate: 'SEPA-Mandat',
    venues: 'Venues',
    createSepa: 'Neues SEPA-Mandat',
    debit: 'SEPA Debit',
    payLast: 'Rechnungen der letzten 30 Tage bezahlen',
    notification: {
      cancelError: 'Fehler beim Löschen des SEPA-Mandats',
      cancelWarning:
        'Sind Sie sich sicher, dieses SEPA-Mandat zu stornieren? Es wird gelöscht und kann nicht mehr für Zahlungen verwendet werden.',
      createWarning:
        'Sind Sie sicher, dass Sie ein neues SEPA-Mandat erstellen wollen? Bestehende SEPA-Mandate für die ausgewählten Venues werden gelöscht und können nicht mehr für Zahlungen verwendet werden.',
      payWarning:
        'SEPA Mandat existiert bereits. Sind Sie sicher, dass Sie ein neues Mandat erstellen wollen für diese Transaktion?',
      payLastTransactionsWarning:
        'Sind Sie sich sicher, Zahlungen für alle Rechnungen der letzten 30 Tage dieser Venues zu veranlassen? Einzelne Zahlungen können danach noch innerhalb von 2 Tagen storniert werden. Kontaktieren Sie in diesem Fall schnellstmöglich den Support.',
      payLastTransactionsSuccess:
        'Rechnungen der letzten 30 Tage bezahlt. Sie erhalten eine E-Mail für jede angewiesene Zahlung. Bitte kontaktieren Sie den Support innerhalb von 2 Tagen, falls Sie eine dieser Zahlungen stornieren möchten.',
    },
    form: {
      merchantId: 'Merchant Id',
      venues: 'Venues',
      merchantName: 'Merchant Name',
      payLastTransactionsAfterCreate: 'Offene Rechnungen der letzten 30 Tage rückwirkend bezahlen *',
      payLastTransactionsAfterCreateHint:
        'Alle offenen Rechnungsbeträge der letzten 30 Tage werden nach Erteilung des SEPA-Mandats von unserem System per Lastschrift beglichen. Sie erhalten eine E-Mail für jede angewiesene Zahlung innerhalb der nächsten Stunden. Bitte kontaktieren Sie den Support innerhalb von 2 Tagen, falls Sie eine dieser Zahlungen stornieren möchten.',
      sepaMandateRef: 'SEPA Mandate Reference',
      sepaMandateRegistrationLink: 'SEPA Mandate Registration Link',
      sepaMandateRegistrationError: 'SEPA Mandate Registration Error',
    },
  },
  productCombos: {
    index: 'Product combos',
    overview: 'Übersicht',
  },
  lightspeed: {
    syncFoodcard: 'Speisekarte synchronisieren',
    updateOnly: 'Nur Änderungen synchronisieren',
    success: 'Lightspeed wurde erfolgreich autorisiert',
    successDescription:
      'Glückwunsch! Lightspeed wurde erfolgreich autorisiert. Bitte kontaktiere deinen Key-Account-Manager, um die Integration abzuschließen. Du kannst diese Seite jetzt schließen.',
    error: 'Lightspeed konnte nicht autorisiert werden',
    errorDescription:
      'Leider ist ein unbekannter Fehler beim Autorisieren von Lightspeed aufgetreten. Bitte kontaktiere den Support oder deinen Key-Account-Manager. Du kannst diese Seite jetzt schließen.',
    notification: {
      foodcardSyncSuccess: 'Speisekarte wurde erfolgreich synchronisiert.',
      foodcardSyncError: 'Speisekarte konnte nicht synchronisiert werden. Bitte Logs überprüfen.',
    },
  },
  screensaver: {
    index: 'Screensaver',
    add: 'Screensaver erstellen',
    mediaType: 'Media type',
    duration: 'Duration',
    level: 'Level',
    preview: 'preview',
    title: 'Screensaver Step',
    hours: 'Hours',
    overview: 'Übersicht',
    stepSavingInfo: 'Die Mediendateien werden gleich geladen. Bitte warten Sie.',
    category: 'Category',
    form: {
      name: 'Name',
      customerGroup: 'Customer Group',
      linkedCustomerGroup: 'Linked Customer Group',
      venues: 'Venues',
      openingHours: 'Öffnungszeiten',
      file: 'Datei',
      duration: 'Dauer in Sekunden',
      level: 'Level',
      saveOrder: 'Save new order',
      changeOrder: 'Change order',
      slug: 'Slug',
      visible: 'Sichtbar',
      category: 'Kategorie',
    },
    tabs: {
      screensaver: 'Screensaver',
      steps: 'SCHRITTE',
      info: 'You can add steps only after creating screensaver',
    },
    table: {
      steps: 'Steps Count',
    },
  },
  copy_venue: {
    not_selected_to: 'Sie müssen einen Zielort zum Kopieren auswählen',
    success: 'Veranstaltungsort wurde erfolgreich kopiert',
    error: 'etwas ist schief gelaufen',
    btn_title: 'Einstellungen kopieren',
    from: 'Von',
    to: 'Zu',
  },
  sync: {
    index: 'Sync',
    sync: 'Sync',
    config: 'Konfig',
    created: 'Erstellt',
    updated: 'Aktualisiert',
    deleted: 'Gelöscht',
    collapseAll: 'Alles ausklappen',
    expandAll: 'Alle erweitern',
    directions: {
      d2d: 'Dev-to-Dev',
      d2p: 'Dev-to-Prod',
      p2d: 'Prod-to-Dev',
      p2p: 'Prod-to-Prod',
    },
    notification: {
      running: 'Der Sync wurde gestartet.',
      error: 'Es ist ein Fehler aufgetreten. Bitte Logs überprüfen.',
    },
    modes: {
      'master-to-slaves': 'Master -> Slaves',
      'slave-to-slaves': 'Slave -> Slaves',
    },
    deleteModes: {
      ignore: 'Keine Änderung/Nicht löschen',
      soft: 'Soft-Delete',
      hard: 'Hard-Delete',
    },
    table: {
      id: 'Id',
      name: 'Name',
      user: 'Benutzer',
      status: 'Status',
      createdAt: 'Erstellt',
      finishedAt: 'Fertig',
      config: 'Konfig',
      changes: 'Änderungen',
    },
    form: {
      main: 'Haupt-Venue (Slave/Master)',
      slaves: 'Slave-Venues',
      direction: 'Von/Nach',
      mode: 'Modus',
      deleteMode: 'Löschmodus',
      articleCategoryProps: 'Kategorie-Props',
      articleProps: 'Artikel-Props',
      venueProps: 'Venue-Props',
      optionGroupProps: 'Optionsgruppe-Props',
      optionArticleProps: 'Optionsartikel-Props',
      selectAll: 'Alle auswählen',
    },
    props: {
      articleCategory: {
        sortOrder: 'Sortierung',
        visible: 'Sichtbarkeit',
        displayMode: 'Display Mode',
        displayIdentifiers: 'Display Identifiers',
        mainCategory: 'Hauptkategorie',
        availableHours: 'Verfügbare Zeiten',
        name: 'Name',
        description: 'Beschreibung',
        assets: 'Hauptbild',
        icon: 'Icon',
        tags: 'Tags',
        constrains: 'Constrains',
        features: 'Features',
        mainRecommendations: 'Hauptempfehlungen',
      },
      article: {
        price: 'Preis',
        basePrice: 'Basis-Preis',
        taxTakeaway: 'Steuer (Abholen)',
        taxInside: 'Steuer (Vorort)',
        sortOrder: 'Sortierung',
        visible: 'Sichtbarkeit',
        isActive: 'Aktivstatus',
        deposit: 'Pfand',
        isControlArticle: 'Kontrolartikel Flag',
        internalNote: 'Interne Notiz',
        internalName: 'Interner Name',
        externalId: 'Externe ID',
        number: 'Nummer',
        priceIsLocked: 'Preis-Lock',
        description: 'Beschreibung',
        name: 'Name',
        info: 'Info',
        shortDescription: 'Kurzbeschreibung',
        assets: 'Hauptbild',
        icon: 'Icon',
        banner: 'Banner',
        measurement: 'Measurement',
        groupDependencies: 'Group Dependencies',
        groups: 'Optionsgruppen',
        defaults: 'Defaults',
        recommendations: 'Empfehlungen',
        priceByType: 'Preis nach Bestellart',
        displayPriceByType: 'Sichtbarer Preis nach Bestellart',
        availability: 'Verfügbarkeit',
        tags: 'Tags',
        constrains: 'Constrains',
        counterArticle: 'Counterarticle',
        mainRecommendations: 'Hauptempfehlungen',
        articleRelativeBrand: 'Brand',
        maxItemsPerCart: 'Max. Anzahl im Warenkorb',
        styles: 'Styles',
        additives: 'Additive',
        allergens: 'Allergene',
        features: 'Features',
        externalOrderProviderSettings: 'Bestellanbietereinstellungen',
        loyaltyId: 'Loyalty Id',
        requiredLoyaltyPoints: 'Benötigte Loyalty Points',
        plu: 'PLU',
        gtin: 'GTIN',
      },
      venue: {
        movDelivery: 'Mindestbestellwert',
        preorderTakeAway: 'Vorbestellung (Abholen)',
        preorderIn: 'Vorbestellung (Vorot)',
        deliveryEnabled: 'Vorbestellung (Lieferung)',
        sendProvisionReportPeriod: 'Provisionsbericht',
        isServiceActivated: 'Service-Status',
        offsets: 'Offsets',
        invoiceReceiver: 'Rechnungsempfänger',
        slot: 'Slotting',
        legal: 'Dokumente',
        openingHours: 'Öffnungszeiten',
        deliveryHours: 'Lieferzeiten',
      },
      optionGroup: {
        hasMultiple: 'Mehrfachauswahl',
        limit: 'Limit',
        sortOrder: 'Sortierung',
        requiredAmount: 'Min. Menge',
        displayMode: 'DisplayMode',
        name: 'Name',
        description: 'Beschreibung',
        backgroundImage: 'Hintergrundbild',
        tags: 'Tags',
      },
      optionArticle: {
        price: 'Preis',
        basePrice: 'Basispreis',
        taxTakeaway: 'Steuer (Abholen)',
        taxInside: 'Steuer (Vorort)',
        sortOrder: 'Sortierung',
        visible: 'Sichtbarkeit',
        isActive: 'Aktivstatus',
        deposit: 'Pfand',
        internalNote: 'Interne Notiz',
        internalName: 'Interner Name',
        externalId: 'Externe ID',
        optionNumber: 'Nummer',
        priceIsLocked: 'Preis-Lock',
        description: 'Beschreibung',
        name: 'Name',
        info: 'Info',
        shortDescription: 'Kurzbeschreibung',
        assets: 'Hauptbild',
        icon: 'Icon',
        measurement: 'Measurement',
        priceByType: 'Preis nach Bestellart',
        displayPriceByType: 'Sichtbarer Preis nach Bestellart',
        availability: 'Verfügbarkeit',
        tags: 'Tags',
        constrains: 'Constrains',
        mainRecommendations: 'Hauptempfehlungen',
        requirements: 'Artikelanforderungen',
        articleRelativeBrand: 'Brand',
        styles: 'Styles',
        additives: 'Additive',
        allergens: 'Allergene',
        features: 'Features',
        externalOrderProviderSettings: 'Bestellanbietereinstellungen',
        loyaltyId: 'Loyalty Id',
        requiredLoyaltyPoints: 'Benötigte Loyalty Points',
        plu: 'PLU',
        gtin: 'GTIN',
      },
    },
  },
  orderProviders: {
    wolt: {
      success: {
        title: 'Venue wurde erfolgreich mit Wolt verbunden',
        description:
          'Deine Venue wurde erfolgreich mit Wolt verbunden. Du kannst nun deine Speisekarte synchronisieren und Bestellungen empfangen.',
      },
      error: {
        title: 'Venue konnte nicht mit Wolt verbunden werden',
        description: 'Es ist ein Fehler aufgetreten. Kontaktiere bitte unseren Support:',
      },
    },
    uberEats: {
      success: {
        title: 'Venue wurde erfolgreich mit Uber Eats verbunden',
        description:
          'Deine Venue wurde erfolgreich mit Uber Eats verbunden. Du kannst nun deine Speisekarte synchronisieren und Bestellungen empfangen.',
      },
      error: {
        title: 'Venue konnte nicht mit Uber Eats verbunden werden',
        description: 'Es ist ein Fehler aufgetreten. Kontaktiere bitte unseren Support:',
      },
    },
  },
  simphony: {
    syncFoodcard: 'Preise/Sichtbarkeit synchronisieren',
    notification: {
      foodcardSyncSuccess: 'Preise/Sichtbarkeit wurde erfolgreich synchronisiert.',
      foodcardSyncError: 'Preise/Sichtbarkeit konnte nicht synchronisiert werden. Bitte Logs überprüfen.',
    },
  },
};
